import React from 'react';

type PropType = {
  children: React.ReactNode;
  data: { [key: string]: string };
};

const TranslationContext = React.createContext({});

export const TranslationProvider = ({ children, data }: PropType) => {
  return (
    <TranslationContext.Provider value={data}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationData = () => {
  return React.useContext(TranslationContext) as { [key: string]: string };
};
