// /*
//  * Updates item in array.
//  */
// export const updateItemInArray = (
//   array,
//   itemIdProp,
//   itemId,
//   updateItemCallback
// ) => {
//   const updatedItems = array.map(item => {
//     if (item[itemIdProp] !== itemId) {
//       // Since we only want to update one item, preserve all others as they are now
//       return item;
//     }

import ContentAreaItem from '../Models/ContentAreaItem.interface';

//     // Use the provided callback to create an updated item
//     const updatedItem = updateItemCallback(item);
//     return updatedItem;
//   });

//   return updatedItems;
// };

// /*
//  * Helper for generating class names.
//  * e.g classnames('foo', { bar: true, duck: false }, 'baz', { quux: true }, true && classname); => 'foo bar baz quux'
//  */
// export const classnames = (...args) =>
//   args
//     .reduce(
//       (classString, arg) =>
//         typeof arg === 'string'
//           ? (classString += ` ${arg}`)
//           : arg
//           ? Object.keys(arg)
//               .reduce(
//                 (acc, key) => (acc += !!arg[key] ? ` ${key}` : ''),
//                 classString
//               )
//               .trim()
//           : classString,
//       ''
//     )
//     .trim();

// /*
//  * Generates an element id for use with block sub navigation
//  */
// export const generateId = (type, heading, index) =>
//   type && heading && index !== undefined
//     ? `${type}-${heading.replace(/\s|"/g, '-')}-${index}`
//     : '';

type HasFileExtension = {
  url: string;
};
export const hasFileExtension = ({ url }: HasFileExtension) =>
  url.indexOf('.') > -1;

//   // Maybe this should be handled in EPI instead on the settings page - simple string list providing the allowed file extension types.
//   const allowedFileTypes = [
//     'pdf',
//     'xls',
//     'xlsx',
//     'csv',
//     'doc',
//     'docx',
//     'jpeg',
//     'png',
//     'gif',
//     'mp4',
//     'ppt',
//     'pptx',
//   ];

//   let path = url.split(/#|\?/)[0];
//   let splitPath = path.split('.');
//   let extension = splitPath[splitPath.length - 1];
//   return allowedFileTypes.includes(extension); // Do we have an extensions?
// };

type IsInternalLinkType = {
  url: string;
};
/*
 * Checks if url is within the domain
 */
export const isInternalLink = ({ url }: IsInternalLinkType) => {
  if (
    (url.charAt(0) === '/' && url.charAt(1) !== '/') ||
    url.charAt(0) === '?' ||
    url.charAt(0) === '#'
  )
    return true;

  const urlPartsQuery = url.split('?');
  if (urlPartsQuery.length > 1) {
    if (urlPartsQuery[0].indexOf(window.location.host) === -1) {
      return false;
    }
    return true;
  }

  const urlPartsHash = urlPartsQuery[0].split('#');
  if (urlPartsHash.length > 1) {
    if (urlPartsHash[0].indexOf(window.location.host) === -1) {
      return false;
    }
    return true;
  }

  if (url.indexOf(window.location.host) !== -1) {
    return true;
  }
  return false;
};

type IsEmailLinkType = {
  url: string;
};
/*
 * Checks if an URL is a mailto link
 */
export const isEmailLink = ({ url }: IsEmailLinkType) => {
  return url.indexOf('mailto:') === 0;
};
/*
 * Parses url sting into parts
 */
export const parseUrl = (url: string) => {
  const matches = url.match(
    /^(([^:/?#]+:)?(?:\/\/((?:([^/?#:]*):([^/?#:]*)@)?([^/?#:]*)(?::([^/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/
  );
  const m = matches === null ? [] : matches;
  //http://username:password@localhost:257/deploy/?asd=asd#asd
  const r = {
    hash: m[10] || '', // #asd
    host: m[3] || '', // localhost:257
    hostname: m[6] || '', // localhost
    href: m[0] || '', // http://username:password@localhost:257/deploy/?asd=asd#asd
    origin: m[1] || '', // http://username:password@localhost:257
    pathname: m[8] || (m[1] ? '/' : ''), // /deploy/
    port: m[7] || '', // 257
    protocol: m[2] || '', // http:
    search: m[9] || '', // ?asd=asd
    username: m[4] || '', // username
    password: m[5] || '', // password
  };
  if (r.protocol.length === 2) {
    r.protocol = 'file:///' + r.protocol.toUpperCase();
    r.origin = r.protocol + '//' + r.host;
  }
  r.href = r.origin + r.pathname + r.search + r.hash;
  return m && r;
};

/*
 * get value for url parameter
 */
export const getUrlParameter = (name: string, queryString?: string) => {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(
    queryString ? queryString : canUseDOM() ? window.location.search : ''
  );
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

/*
 * Used to check if we are in server context or not.
 */
export const canUseDOM = () => {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
};

export const updateUrl = (pageTitle: string, url: string) => {
  const state = { id: new Date().valueOf() };
  window.history.pushState(state, pageTitle, url);
};

export const getFormattedDate = (dateString: string) => {
  const date = new Date(Date.parse(dateString));

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const joined = [year, month, day].join('-');

  return joined;
};

// /*
//  * Trigger a browser resize event
//  */
export const triggerResizeEvent = () => {
  setTimeout(() => {
    if (canUseDOM()) {
      if (
        navigator.userAgent.indexOf('MSIE') !== -1 ||
        navigator.appVersion.indexOf('Trident/') > 0
      ) {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
      } else {
        window.dispatchEvent(new Event('resize'));
      }
    }
  }, 0);
};

// /**
//  * native scroll in to view function
//  * @param {*} elm
//  */
// export const ScrollIntoView = elm => {
//   !Object.isEmpty(elm) &&
//     elm.scrollIntoView({
//       behavior: 'smooth',
//     });
// };

/**
 * Scroll to function
 * @param {*} elm - element to scroll to - Could be a saved ref.current
 */
let scrollTimeouts: NodeJS.Timeout[] = [];
export const ScrollToEle = (ele: HTMLElement) => {
  if (scrollTimeouts.length) {
    scrollTimeouts.forEach((timeOut: NodeJS.Timeout) => {
      clearTimeout(timeOut);
    });
    scrollTimeouts = [];
  }

  function currentYPosition() {
    // Firefox, Chrome, Opera, Safari
    if (window.pageYOffset) return window.pageYOffset;
    // Internet Explorer 6 - standards mode
    if (document.documentElement && document.documentElement.scrollTop)
      return document.documentElement.scrollTop;
    // Internet Explorer 6, 7 and 8
    if (document.body.scrollTop) return document.body.scrollTop;
    return 0;
  }

  function elmYPosition(elm: HTMLElement) {
    let y = elm.offsetTop;
    return y - 30;
  }

  const doScroll = (leapY: number, timer: number, speed: number) =>
    new Promise((resolve) => {
      scrollTimeouts.push(
        global.setTimeout(() => {
          window.scrollTo(0, leapY);
          resolve();
        }, timer * speed)
      );
    });

  return new Promise((resolve) => {
    let startY = currentYPosition();
    let stopY = elmYPosition(ele);
    let distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
      window.scrollTo(0, stopY);
      resolve();
    }
    let speed = Math.round(distance / 100);
    if (speed >= 20) speed = 20;
    let step = Math.round(distance / 25) < 1 ? 1 : Math.round(distance / 25);
    let leapY = stopY > startY ? startY + step : startY - step;
    let timer = 0;
    const promises = [];
    if (stopY > startY) {
      for (let i = startY; i < stopY; i += step) {
        promises.push(doScroll(leapY, timer, speed));
        leapY += step;
        if (leapY > stopY) leapY = stopY;
        timer++;
      }
      Promise.all(promises).then(() => {
        scrollTimeouts = [];
        resolve();
      });
      return;
    }
    for (let i = startY; i > stopY; i -= step) {
      promises.push(doScroll(leapY, timer, speed));
      leapY -= step;
      if (leapY < stopY) leapY = stopY;
      timer++;
    }
    Promise.all(promises).then(() => {
      scrollTimeouts = [];
      resolve();
    });
  });
};

export const scrollWithOffset = (el: HTMLElement) => {
  if (!el) return;
  const elementOffset = getDocumentOffsetPosition(el);
  let headerElm = document.getElementById('header');
  if (headerElm) {
    window.scrollTo({
      top: elementOffset.top - headerElm.offsetHeight - 20,
      behavior: 'smooth',
    });
  }
};

const getDocumentOffsetPosition = (el: HTMLElement): Record<string, number> => {
  const position = {
    top: el.offsetTop,
    left: el.offsetLeft,
  };

  if (el.offsetParent) {
    const parentPosition = getDocumentOffsetPosition(
      el.offsetParent as HTMLElement
    );
    position.top += parentPosition.top;
    position.left += parentPosition.left;
  }
  return position;
};

// /*
//  * Inorder to not get a hacky scroll when we already have loaded the compononents.
//  * especially for contentarea
//  */
// export const loadedComponents = [];

/*
 * A web storage helper, for both sessionStorage and localStorage
 */
type WebStorageHelperType = {
  type: 'sessionStorage' | 'localStorage';
  storageKey: string;
  defaultValue: string | object | boolean;
};
export const webStorageHelper = ({
  type,
  storageKey,
  defaultValue,
}: WebStorageHelperType) => {
  const get = (key?: string) => {
    const item = window[type].getItem(storageKey);
    let data = item ? JSON.parse(item) : null;
    data = data === null ? defaultValue : data;
    return key ? data[key] : data;
  };
  const set = (val: string | object | boolean): void =>
    window[type].setItem(storageKey, JSON.stringify(val));

  if (!window[type].getItem(storageKey)) {
    set(defaultValue);
  }
  return {
    get,
    set,
  };
};

type UseTopPaddingType = {
  firstBlock: ContentAreaItem;
};

export const useTopPadding = ({ firstBlock }: UseTopPaddingType) => {
  if (firstBlock.blockType === 'HeroBlock') {
    return false;
  }

  return true;
};

// /*
//  * Coverts object to url params
//  */
// export const objectToUrlParams = object => {
//   return (
//     '?' +
//     Object.keys(object)
//       .filter(key => !!object[key])
//       .map(key => `${key}=${encodeURIComponent(object[key])}`)
//       .join('&')
//   );
// };

// /*
//  * Gets absolute height of element (margin and borders)
//  */
// export const getAbsoluteHeight = el => {
//   // Get the DOM Node if you pass in a string
//   el = typeof el === 'string' ? document.querySelector(el) : el;

//   var styles = window.getComputedStyle(el);
//   var margin =
//     parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

//   return Math.ceil(el.offsetHeight + margin);
// };

// /*
//  * Checks if element fits in viewport.
//  * Possible to pass in margins in the calculation, e.g include the header's height
//  */
// export const isElementInViewport = (
//   el,
//   { topMargin = 0, rightMargin = 0, bottomMargin = 0, leftMargin = 0 } = {}
// ) => {
//   var rect = el.getBoundingClientRect();
//   return (
//     rect.top >= topMargin &&
//     rect.left >= leftMargin &&
//     rect.bottom <=
//       (window.innerHeight || document.documentElement.clientHeight) -
//         bottomMargin &&
//     rect.right <=
//       (window.innerWidth || document.documentElement.clientWidth) - rightMargin
//   );
// };

// /**
//  * Trigger animation on element
//  *
//  * @param {*} elm
//  * @param {*} className
//  */
// export const triggerAnimation = (elm, className) => {
//   elm.classList.remove(className);
//   void elm.offsetWidth;
//   elm.classList.add(className);
// };

// /**
//  * Take form element and returns it's form elements
//  * values serialized in string format
//  *
//  * @param {*} form
//  */
// export const getFormSerializedString = form => {
//   return Array.from(new FormData(form), e =>
//     e.map(value => value).join('=')
//   ).join('&');
// };

// /**
//  * takes a query string and transforms it to an object
//  * if no query string is provided it's using window location search
//  * @param {*} query
//  */
// export const getQueryStringParams = queryString => {
//   let query = queryString || window.location.search;
//   return query
//     ? (/^[?#]/.test(query) ? query.slice(1) : query)
//         .split('&')
//         .reduce((params, param) => {
//           let [key, value] = param.split('=');
//           if (!params[key]) params[key] = [];
//           value &&
//             params[key].push(decodeURIComponent(value.replace(/\+/g, ' ')));
//           return params;
//         }, {})
//     : {};
// };

// /**
//  * function for mimic optional chaining,
//  *
//  * @param {*} obj - object to check prop for
//  * @param {*} properties - string which represent the propeties, 'a.b.c'
//  * @param {*} defaultValue
//  */
// export const getObjectProperty = (obj, properties, defaultValue) => {
//   if (!obj) return defaultValue;
//   const props = properties.split('.');
//   let objProp = obj;
//   for (let index = 0; index < props.length; index++) {
//     if (objProp[props[index]] !== undefined) {
//       objProp = objProp[props[index]];
//     } else {
//       objProp = defaultValue;
//       break;
//     }
//   }
//   return objProp;
// };

// /**
//  * Does what the function names says
//  */
// export const getScrollbarWidth = () => {
//   var inner = document.createElement('p');
//   inner.style.width = '100%';
//   inner.style.height = '200px';

//   var outer = document.createElement('div');
//   outer.style.position = 'absolute';
//   outer.style.top = '-200px';
//   outer.style.left = '-300px';
//   outer.style.visibility = 'hidden';
//   outer.style.width = '200px';
//   outer.style.height = '150px';
//   outer.style.overflow = 'hidden';
//   outer.appendChild(inner);

//   document.body.appendChild(outer);
//   var w1 = inner.offsetWidth;
//   outer.style.overflow = 'scroll';
//   var w2 = inner.offsetWidth;
//   if (w1 === w2) w2 = outer.clientWidth;

//   document.body.removeChild(outer);

//   return w1 - w2;
// };

// /**
//  * Usefull when you need to wrapp content based on a condition
//  * @param {*} condition - condition if wrap or not
//  * @param {*} wrapper - wrapper function/Component
//  * @param {*} children - as in <ConditionalWrapper>children</ConditionalWrapper>
//  */
// export const ConditionalWrapper = ({ condition, wrapper, children }) =>
//   condition ? wrapper(children) : children;
