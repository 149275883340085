import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function Logo({ color, size }: IconBaseType) {
  return (
    <StyledSvg
      size={size}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 15"
    >
      <StyledPath
        color={color}
        d="M3.428 14.219v-3.75l1.406-1.543 3.027 5.293h4.063l-4.805-7.93L11.924 0H7.686L4.619 4.463 3.428 6.27V0H0v14.219h3.428zm21.16 0v-2.637H18.22V8.193h5.39V5.645h-5.39V2.646h6.386V0h-9.814v14.219h9.795zm6.307 0l2.392-4.815 2.393 4.815h3.955L35.553 7.05 39.527 0h-3.916l-2.324 4.727L30.963 0h-3.906l3.964 7.05-4.072 7.169h3.946z"
        fillRule="nonzero"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default Logo;
