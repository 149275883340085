import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function ContactIcon({ css, size, color, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 12"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        d="M10 0c1.105 0 2 .895 2 2v5c0 1.105-.895 2-2 2H7.165l-2.458 2.68c-.39.426-1.024.426-1.414 0C3.105 11.477 3 11.2 3 10.91V9H2C.895 9 0 8.105 0 7V2C0 .895.895 0 2 0h8zm0 1H2c-.513 0-.936.386-.993.883L1 2v5c0 .513.386.936.883.993L2 8h2l.001 2.93.007.032L6.725 8H10c.513 0 .936-.386.993-.883L11 7V2c0-.513-.386-.936-.883-.993L10 1zM6.5 5c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3zm2-2c.276 0 .5.224.5.5s-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default ContactIcon;
