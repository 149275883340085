import React from 'react';
import { styled } from '../../stitches.config';
import { BaseIconStyle, BaseIconCircleStyle } from './LoaderBaseStyle';

type PageLoaderType = {
  loading?: boolean;
};

const PageLoader = ({ loading }: PageLoaderType) => {
  return (
    <OuterDiv isLoading={loading}>
      <InnerDiv isLoading={loading}>
        <BaseIconStyle isLoading={loading} viewBox="0 0 50 50" size={'large'}>
          <BaseIconCircleStyle
            isLoading={loading}
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          />
        </BaseIconStyle>
      </InnerDiv>
    </OuterDiv>
  );
};

const OuterDiv = styled('div', {
  position: 'fixed',
  w: '100vw',
  h: '100vh',
  zIndex: '$pageLoader',
  overflow: 'hidden',
  top: 0,
  right: 0,
  opacity: '0',
  transition: 'opacity $300 cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  pointerEvents: 'none',
  variants: {
    isLoading: {
      true: {
        opacity: 1,
      },
    },
  },
});

const InnerDiv = styled('div', {
  position: 'absolute',
  top: 'calc(40%)',
  right: '50%',
  transform: 'translate(50%, -50%)',
  backgroundColor: '#fff',
  borderRadius: '50%',
  w: 20,
  h: 20,
  boxShadow: '0px 10px 10px -10px black',
  transition: 'opacity $300 cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  transitionDelay: '$500',
  opacity: '0',
  variants: {
    isLoading: {
      true: {
        opacity: 1,
      },
    },
  },
});

export default PageLoader;
