import React, { useEffect, useRef } from 'react';
import { isInternalLink } from '../../Shared/Common/Helpers';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import { styled } from '../../stitches.config';
import {
  BaseH1Style,
  BaseH2Style,
  BaseH3Style,
} from '../../Atoms/Typography/Headings/BaseHeadingStyle';
import { BaseParagraphStyle } from '../../Atoms/Typography/Paragraph/BaseParagraphStyle';
import { BaseKexLinkStyle } from '../../Kex/BaseKexLinkStyle';
import {
  BaseListStyle,
  BaseListItemStyle,
} from '../../Atoms/List/BaseListStyle';

type PropType = {
  content: string;
};

const HtmlArea = ({ content, ...rest }: PropType) => {
  const elmRef = useRef<HTMLDivElement>(null);
  const kexNavigate = useKexNavigate();
  useEffect(() => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      const url = e.currentTarget.href;
      if (e.currentTarget.target !== '_blank' && isInternalLink({ url })) {
        e.preventDefault();
        kexNavigate(url);
      }
    };

    const links: HTMLAnchorElement[] = elmRef.current
      ? [].slice.call(elmRef.current.querySelectorAll('a'))
      : [];

    links.forEach((link) => link.addEventListener('click', () => onClick));
    return () =>
      links.forEach((link) => link.removeEventListener('click', () => onClick));
  }, [kexNavigate]);

  return (
    <StyledHtmlArea
      {...rest}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  );
};

const StyledHtmlArea = styled('div', {
  '& h1': BaseH1Style,
  '& h2': BaseH2Style,
  '& h3': BaseH3Style,
  '& p': BaseParagraphStyle,
  '& a': BaseKexLinkStyle,
  '& ul': BaseListStyle,
  '& li': BaseListItemStyle,
  '> *': {
    display: 'block',
    mb: 2,
  },
});

export default HtmlArea;
