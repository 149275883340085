import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function CartIcon({ css, color, size, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        d="M6.625 21C6.15833 21 5.771 20.846 5.463 20.538C5.15433 20.2293 5 19.8417 5 19.375V8.625C5 8.15833 5.15433 7.771 5.463 7.463C5.771 7.15433 6.15833 7 6.625 7H8.5V6.5C8.5 5.53333 8.84167 4.70833 9.525 4.025C10.2083 3.34167 11.0333 3 12 3C12.9667 3 13.7917 3.34167 14.475 4.025C15.1583 4.70833 15.5 5.53333 15.5 6.5V7H17.375C17.8417 7 18.2293 7.15433 18.538 7.463C18.846 7.771 19 8.15833 19 8.625V19.375C19 19.8417 18.846 20.2293 18.538 20.538C18.2293 20.846 17.8417 21 17.375 21H6.625ZM6.625 20H17.375C17.5417 20 17.6873 19.9373 17.812 19.812C17.9373 19.6873 18 19.5417 18 19.375V8.625C18 8.45833 17.9373 8.31267 17.812 8.188C17.6873 8.06267 17.5417 8 17.375 8H15.5V10.5C15.5 10.65 15.4543 10.771 15.363 10.863C15.271 10.9543 15.15 11 15 11C14.85 11 14.729 10.9543 14.637 10.863C14.5457 10.771 14.5 10.65 14.5 10.5V8H9.5V10.5C9.5 10.65 9.45433 10.771 9.363 10.863C9.271 10.9543 9.15 11 9 11C8.85 11 8.729 10.9543 8.637 10.863C8.54567 10.771 8.5 10.65 8.5 10.5V8H6.625C6.45833 8 6.31267 8.06267 6.188 8.188C6.06267 8.31267 6 8.45833 6 8.625V19.375C6 19.5417 6.06267 19.6873 6.188 19.812C6.31267 19.9373 6.45833 20 6.625 20ZM9.5 7H14.5V6.5C14.5 5.8 14.2583 5.20833 13.775 4.725C13.2917 4.24167 12.7 4 12 4C11.3 4 10.7083 4.24167 10.225 4.725C9.74167 5.20833 9.5 5.8 9.5 6.5V7ZM6 20V8V20Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default CartIcon;
