import { useState, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { useKexLoadingCircle } from '../../../Kex/KexLoadingCircle';
import { IS_PRODUCTION_ENV } from '../../Configs/EnvConfig';
import FetchHeader from '../../../Organisms/Header/FetchHeader';
import IHeader from './Header.interface';
import useCurrentPage from '../../Hooks/useCurrentPage';
import { useAppSettingsData } from '../../Providers/AppSettingsProvider';

let hasMounted = false;
let litiumContext = '';
let url = '';

export default function (initialData: IHeader): [IHeader] {
  const [data, setData] = useState<IHeader>(initialData);
  const kexLoadingCircleDispatch = useKexLoadingCircle();
  const { languageRoute, startPageId } = useAppSettingsData();
  const { channelId } = useCurrentPage();

  litiumContext = JSON.stringify({
    currentPageSystemId: startPageId,
    channelSystemId: channelId,
  });

  url = `/api/${languageRoute || 'en'}/app/GetStandardHeader`;

  const queryData = useSWR<IHeader>(
    url,
    () => FetchHeader(url, litiumContext),
    {
      fallbackData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch(!queryData.data ? 'add' : 'remove');
  }, [kexLoadingCircleDispatch, queryData.data]);

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}

export async function updateHeaderData(header: IHeader) {
  mutate(url, header, false);
}

export async function updateHeader() {
  const res = await FetchHeader(url, litiumContext);
  if (res) {
    mutate(url, res, false);
  }
}
