import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';
import { timings } from '../../Theme/Settings/animation';

type PropTypes = IconBaseType & {
  isSelected: boolean;
  transition?: boolean;
};

function GridIcon({
  size,
  space,
  color,
  css,
  transition,
  onBackground,
  isSelected,
  ...rest
}: PropTypes) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      transition={transition}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <StyledPath
        d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
      <StyledPath
        d="M17.5013 2.5H11.668V8.33333H17.5013V2.5Z"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
      <StyledPath
        d="M17.5013 11.6666H11.668V17.5H17.5013V11.6666Z"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
      <StyledPath
        d="M8.33333 11.6666H2.5V17.5H8.33333V11.6666Z"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {
  variants: {
    transition: {
      true: {
        transitionDuration: timings.oneFifth,
        transitionProperty: 'all',
      },
    },
  },
});
const StyledPath = styled(BaseIconPathStyle, {
  transitionDuration: timings.oneFifth,
  transitionProperty: 'all',
  variants: {
    isSelected: {
      true: {
        stroke: '#484848',
      },
    },
  },
});

export default GridIcon;
