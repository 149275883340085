import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function PayEx({ css, color, size, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      color={color}
      size={size}
      css={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37 26"
    >
      <StyledPath
        color={color}
        onBackground={onBackground}
        d="M7.52 16.287h4.185c.714 0 1.272-.558 1.272-1.271 0-.714-.558-1.24-1.272-1.24H7.52c-.713 0-1.27.557-1.27 1.27 0 .714.588 1.24 1.27 1.24zM34.743.442H1.287C.574.442.016 1 .016 1.713V9.62c-.032.093-.032.186-.032.28 0 .092 0 .185.032.278v14.078c0 .713.558 1.271 1.27 1.271h33.458c.713 0 1.272-.558 1.272-1.271V1.713c0-.682-.559-1.271-1.272-1.271zm-1.271 22.574H2.558V11.17h30.915v11.845zm0-14.357H2.558V2.984h30.915V8.66z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default PayEx;
