import React from 'react';
import KexLink from '../../../../../Kex/KexLink';
import ILink from '../../../Link.interface';
import { H4 } from '../../../../../Atoms/Typography/Headings/Heading';
import { styled } from '../../../../../stitches.config';

type PropTypes = {
  header?: string;
  links?: ILink[];
};

function FooterList({ header, links }: PropTypes) {
  return (
    <>
      {header && <H4 css={TitleStyle}>{header}</H4>}
      <List>
        {links &&
          links.map((item: ILink, index: number) => (
            <ListItem key={item.href && item.href + index}>
              <Link href={item.href}>{item.text}</Link>
            </ListItem>
          ))}
      </List>
    </>
  );
}

const TitleStyle = {
  fontSize: '24px',
  fontWeight: '$fw600',
  lineHeight: '32px',
  color: '#000',
  mx: 0,
  pb: 2,
  position: 'relative',
  display: 'block',
};

const List = styled('ul', {
  listStyle: 'none',
  px: 0,
  '@mediaMaxLarge': {
    pb: 4.5,
  },
});

const ListItem = styled('li', {
  mt: 2,
  ls: '$ls08',
});

const Divider = styled('div', {
  w: 16,
  h: 0.5,
  backgroundColor: '$secondary',
  mt: 2,
});

const Link = styled(KexLink, {
  fontSize: '14px',
  fontWeight: '$fw400',
  color: '#000',
  lineHeight: '24px',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export default FooterList;
