import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import {
  EventDispatcher,
  NOTIFY_ACTION,
  STOP_NOTIFY_ACTION,
} from '../../Shared/Common/EventDispatcher';
import BaseNotification from '../../Shared/Notifications/Models/BaseNotification.interface';

const KexNotification = loadable(() => import('./KexNotificationBanner'));

function KexNotificationOverlay() {
  const tempNoteObject: BaseNotification = {} as BaseNotification;
  const [notify, setNotify] = useState<BaseNotification>(tempNoteObject);

  const onNotify = (notification: BaseNotification) => {
    setNotify(notification);
    Decontruct();
  };

  const stopNotify = (notification: BaseNotification) => {
    setNotify(notification);
  };

  useEffect(() => {
    EventDispatcher.subscribe(NOTIFY_ACTION, onNotify);
    EventDispatcher.subscribe(STOP_NOTIFY_ACTION, stopNotify);

    return () => {
      EventDispatcher.unsubscribe(NOTIFY_ACTION, onNotify);
      EventDispatcher.unsubscribe(STOP_NOTIFY_ACTION, stopNotify);
    };
  });
  return GetNotification(notify);
}

function Decontruct() {
  setTimeout(() => {
    EventDispatcher.dispatch(STOP_NOTIFY_ACTION, {} as BaseNotification);
  }, 5000);
}

function GetNotification(notification: any) {
  const loadableNotifications = {
    BaseNotification: loadable(() => import('./KexNotificationBanner')),
    CartNotification: loadable(() => import('./KexCartNotificationBanner')),
  };
  const LoadableNotification = (loadableNotifications as any)[
    notification.type
  ];
  if (LoadableNotification === undefined)
    return <KexNotification {...notification} />;
  return <LoadableNotification {...notification} />;
}

export default KexNotificationOverlay;
