import React from 'react';
import { styled } from '../../stitches.config';

type PropType = {
  children: React.ReactNode;
};

function ContentContainer({ children, ...rest }: PropType) {
  return <Container {...rest}>{children}</Container>;
}

export default ContentContainer;

const Container = styled('div', {
  w: '100%',
  maxW: '$contentMaxWidth',
  mx: 'auto',
  py: 0,
  px: 4,
  '@mediaMinLarge': {
    px: 5,
  },
});
