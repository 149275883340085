import CloseIcon from '../../Atoms/Icons/CloseIcon';
import { styled } from '../../stitches.config';
import { H2 } from '../../Atoms/Typography/Headings/Heading';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';

function ReporterModal() {
  const {
    'error/errorHeader': errorHeader,
    'error/errorRequestText': errorRequestText,
  } = useTranslationData();

  return (
    <>
      <Root>
        <H2>{errorHeader}</H2>
        <CloseButton
          onClick={() => {
            window.location.reload();
          }}
        >
          <StyledCloseIcon>
            <CloseIcon />
          </StyledCloseIcon>
        </CloseButton>
        <Paragraph>{errorRequestText}</Paragraph>
      </Root>
    </>
  );
}

export default ReporterModal;

const Root = styled('div', {
  w: '100%',
  backgroundColor: '$grey200',
  p: 10,
  h: '100%',
  position: 'fixed',
  zIndex: '$errorModal',
});

const StyledCloseIcon = styled('span', {
  h: 8,
  w: 8,
  mr: 2,
});

const CloseButton = styled('button', {
  position: 'absolute',
  r: 11.75,
  t: 11.75,
});
