import { BreadcrumbList, WithContext } from 'schema-dts';
import Link from '../../Shared/Models/Link.interface';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { StructuredData } from './StructuredData';

function StructuredBreadcrumbData({ breadcrumbs }: { breadcrumbs?: Link[] }) {
  const { domain } = useAppSettingsData();

  if (breadcrumbs) {
    const data: WithContext<BreadcrumbList> = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.map((link, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: link.text,
        item: `${domain}${link.href}`,
      })),
    };

    return <StructuredData json={data} />;
  } else return null;
}

export { StructuredBreadcrumbData };
