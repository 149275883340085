// Documentation for stiches --> https://stitches.dev/docs/styling
import { createStitches } from '@stitches/react';
const { createTheme } = createStitches({});

const dark = createTheme({
  colors: {
    rootBackgroundColor: '$grey1000',
    primary: '#F7EDDA',
    buttonPrimary: '$white',
    buttonSecondary: '$grey1100',
    buttonLoading: '$grey400',
    textPrimary: '$grey500',
    textSecondary: '$grey1000',
    fillSecondary: '$grey1000',
    fillPrimary: '$grey500',
    breadcrumbBackgroundPrimary: '$grey1000',
  },
});

export default dark;
