import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function SortingIcon({ css, color, size, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      color={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 16"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        d="M12 3c.276 0 .5.19.5.425v6.987l2.675-2.292a.5.5 0 11.65.76l-3.5 3a.5.5 0 01-.65 0l-3.5-3a.5.5 0 11.65-.76l2.675 2.292V3.425c0-.235.224-.425.5-.425zM5.5 6a.5.5 0 010 1h-5a.5.5 0 010-1h5zm2-3a.5.5 0 010 1h-7a.5.5 0 010-1h7zm2-3a.5.5 0 010 1h-9a.5.5 0 010-1h9z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default SortingIcon;
