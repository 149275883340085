// Documentation for stiches --> https://stitches.dev/docs/styling
import { createStitches } from '@stitches/react';
import { mediaQueryTypes } from './Theme/Settings/mediaQueries';
import { utils } from './Theme/Settings/utils';
import { colors } from './Theme/Settings/colors';
import sizes from './Theme/Settings/sizes';

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors,
    sizes,
    fontWeights: {
      fw300: 300,
      fw400: 400,
      fw600: 600,
      fw700: 700,
    },
    lineHeights: {
      lh1: 1,
      lh125: 1.25,
      lh13: 1.3,
      lh15: 1.5,
      lh1625: 1.625,
      lh175: 1.75,
      lh2: 2,
    },
    letterSpacings: {
      ls0: '0px',
      ls05: '0.5px',
      ls06: '0.6px',
      ls07: '0.7px',
      ls08: '0.8px',
      ls1: '1px',
      ls113: '1.13px',
      ls125: '1.25px',
      ls15: '1.5px',
      ls1625: '1.625px',
      ls163: '1.63px',
      ls175: '1.75px',
      ls2: '2px',
      ls275: '2.75px',
      ls3: '3px',
      ls8: '8px',
    },
    zIndices: {
      Notification: 9,
      Header: 10,
      HeaderMobile: 8,
      Flyout: 9,
      DropDown: 10,
      QuantityDropDown: 10,
      FlyoutLower: 5,
      Cart: 10,
      CartMobile: 11,
      Modal: 20,
      Overlay: 6,
      pageLoader: 990,
      errorModal: 1000,
    },
    transitions: {
      '200': '200ms',
      '300': '300ms',
      '400': '400ms',
      '500': '500ms',
      '600': '600ms',
      '700': '700ms',
      '1000': '1000ms',
      '1500': '1500ms',
      '5000': '5000ms',
    },
  },
  utils,
  media: mediaQueryTypes,
});
