import { styled } from '../../stitches.config';

export const BaseIconStyle = styled('svg', {
  overflow: 'visible',
  mx: 1,
  variants: {
    size: {
      xs: {
        wh: 3,
      },
      s: {
        wh: 4,
      },
      m: {
        wh: 5,
      },
      l: {
        wh: 6,
      },
      xl: {
        wh: 8,
      },
      xxl: {
        wh: 15,
      },
      header: {
        h: 8,
        w: 18,
      },
      footer: {
        h: 6,
        w: 16,
      },
      flag: {
        h: 3,
        w: 4.5,
      },
      quantity: {
        wh: 2.25,
      },
      trashCan: {
        width: '12px',
        height: '14px',
      },
      clearCartTrashCan: {
        width: '24px',
        height: '24px',
      },
    },
    space: {
      true: {
        mr: 2,
      },
    },
    color: {
      p: {
        fill: '$textPrimary',
      },
      s: {
        fill: '$textSecondary',
      },
      a: {
        fill: '$accent',
      },
      e: {
        fill: '$errorText',
      },
    },
    noMargin: {
      true: {
        mx: 0,
      },
    },
  },
  defaultVariants: {
    size: 's',
    fill: '$fillPrimary',
  },
});

const baseVariations = {
  variants: {
    color: {
      primary: {
        fill: '$fillPrimary',
      },
      secondary: {
        fill: '$fillSecondary',
      },
      accent: {
        fill: '$fillAccent',
      },
      error: {
        fill: '$fillError',
      },
      white: {
        fill: '$fillWhite',
      },
      toggle: {
        fill: '$toggleActiveColor',
      },
      transparent: {
        fill: 'transparent',
      },
      green: {
        fill: '$green',
      },
    },
    onBackground: {
      true: {},
    },
  },
  defaultVariants: {
    fill: '$fillPrimary',
  },
  compoundVariants: [
    {
      color: 'primary',
      onBackground: true,
      css: {
        fill: '$textSecondary',
      },
    },
    {
      color: 'secondary',
      onBackground: true,
      css: {
        fill: '$textPrimary',
      },
    },
  ],
};

export const BaseIconPathStyle = styled('path', { ...baseVariations });

export const BaseIconCircleStyle = styled('circle', { ...baseVariations });
