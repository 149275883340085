import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function ExclamationMarkIcon({
  css,
  size,
  color,
  space,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        transform="translate(-781 -510) translate(765 172) translate(0 316) translate(16 22)"
        d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 1C4.134 1 1 4.134 1 8s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm.5 10v2h-1v-2h1zm0-8v7h-1V3h1z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default ExclamationMarkIcon;
