import React, { useState, useEffect } from 'react';
import {
  EventDispatcher,
  ON_KEX_ERROR,
} from '../Shared/Common/EventDispatcher';
import ReporterModal from '../../Features/Molecules/ReporterModal/ReporterModal';

function KexReporterOverlay() {
  const [errorCount, setErrorCount] = useState(0);

  const onError = () => {
    setErrorCount(1);
  };

  useEffect(() => {
    EventDispatcher.subscribe(ON_KEX_ERROR, onError);

    return () => {
      EventDispatcher.unsubscribe(ON_KEX_ERROR, onError);
    };
  });

  return <>{errorCount !== 0 && <ReporterModal />}</>;
}

export default KexReporterOverlay;
