import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function CirclePlusIcon({
  css,
  size,
  color,
  space,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 1a7 7 0 100 14A7 7 0 008 1zm0 2.5a.5.5 0 01.492.41L8.5 4v3.5H12a.5.5 0 01.09.992L12 8.5H8.5V12a.5.5 0 01-.992.09L7.5 12V8.5H4a.5.5 0 01-.09-.992L4 7.5h3.5V4a.5.5 0 01.5-.5z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default CirclePlusIcon;
