import { styled } from '../../stitches.config';
import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function FilterIcon({ css, size, color, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        d="M8 10c1.103 0 2 .897 2 2s-.897 2-2 2a1.998 1.998 0 01-1.884-1.35l-.045-.15H.5a.5.5 0 01-.09-.992L.5 11.5h5.571c.224-.86 1-1.5 1.929-1.5zm0 1a1 1 0 100 2 1 1 0 000-2zm7.5.5a.5.5 0 010 1h-4a.5.5 0 010-1h4zM6 5c.874 0 1.613.567 1.884 1.35l.045.15H15.5a.5.5 0 01.09.992l-.09.008H7.929C7.705 8.36 6.929 9 6 9c-1.103 0-2-.897-2-2s.897-2 2-2zm0 1a1 1 0 100 2 1 1 0 000-2zm-3.5.5a.5.5 0 010 1h-2a.5.5 0 010-1h2zM10 0c1.103 0 2 .897 2 2s-.897 2-2 2a1.998 1.998 0 01-1.884-1.35l-.045-.15H.5a.5.5 0 01-.09-.992L.5 1.5h7.571C8.295.64 9.071 0 10 0zm0 1a1 1 0 100 2 1 1 0 000-2zm5.5.5a.5.5 0 010 1h-2a.5.5 0 010-1h2z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default FilterIcon;
