import React, { useEffect, useState } from 'react';
import darkTheme from './dark.theme';
import { useAppSettingsData } from '../Shared/Providers/AppSettingsProvider';
import { EventDispatcher, SET_THEME } from '../Shared/Common/EventDispatcher';

type PropType = {
  theme: string;
  children: React.ReactNode;
};

const loadableThemes = {
  KexDark: darkTheme,
};

export const ThemeProvider = ({ theme, children }: PropType) => {
  const [siteTheme, setTheme] = useState<string>(
    useAppSettingsData().siteTheme
  );

  const onThemeSwitch = (theme: string) => {
    setTheme(siteTheme);
  };

  useEffect(() => {
    EventDispatcher.subscribe(SET_THEME, onThemeSwitch);

    return () => {
      EventDispatcher.unsubscribe(SET_THEME, onThemeSwitch);
    };
  });

  return <div className={(loadableThemes as any)[siteTheme]}>{children}</div>;
};

//TODO: CREATE THEM SETTER
