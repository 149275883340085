import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';
import { timings } from '../../Theme/Settings/animation';

type PropTypes = IconBaseType & {
  isSelected: boolean;
  transition?: boolean;
};

function ListViewIcon({
  size,
  space,
  color,
  css,
  transition,
  onBackground,
  isSelected,
  ...rest
}: PropTypes) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      transition={transition}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <StyledPath
        d="M6.66797 5H17.5013"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
      <StyledPath
        d="M6.66797 10H17.5013"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
      <StyledPath
        d="M6.66797 15H17.5013"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
      <StyledPath
        d="M2.5 5H2.50833"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
      <StyledPath
        d="M2.5 10H2.50833"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
      <StyledPath
        d="M2.5 15H2.50833"
        strokeWidth="1.5px"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="#A3A3A3"
        color={'transparent'}
        isSelected={isSelected}
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {
  variants: {
    transition: {
      true: {
        transitionDuration: timings.oneFifth,
        transitionProperty: 'all',
      },
    },
  },
});
const StyledPath = styled(BaseIconPathStyle, {
  transitionDuration: timings.oneFifth,
  transitionProperty: 'all',
  variants: {
    isSelected: {
      true: {
        stroke: '#484848',
      },
    },
  },
});

export default ListViewIcon;
