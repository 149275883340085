import React, { useRef, useState, useEffect } from 'react';
import PlusIcon from '../../Atoms/Icons/PlusIcon';
import { ChevronIcon } from '../../Atoms/Icons';
import { styled } from '../../stitches.config';
import { timings } from '../../Theme/Settings/animation';

type PropType = {
  title: React.ReactNode;
  children: React.ReactNode;
  type?: string;
  defaultActive?: boolean;
  topBorder?: boolean;
  open?: boolean;
};

function Accordion({
  title,
  children,
  type,
  defaultActive = false,
  topBorder,
  open,
}: PropType) {
  const [active, setActive] = useState<boolean>(defaultActive);
  const [setHeight, setHeightState] = useState<string>('0px');
  const content = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setActive(!active);
    setHeightState(active ? '0px' : `${content?.current?.scrollHeight}px`);
  };

  useEffect(() => {
    open && toggleAccordion();
  }, [open]);

  useEffect(() => {
    setActive(defaultActive);
    setHeightState(!active ? '0px' : `${content?.current?.scrollHeight}px`);
    open && toggleAccordion();
  }, [defaultActive]);
  return (
    <StyledAccordion
      type={type}
      {...(active && { active })}
      {...(topBorder && { topBorder })}
    >
      <StyledToggle type={type} onClick={toggleAccordion}>
        <span>{title}</span>
        <IconWrapper type={type} isOpen={active}>
          {type === 'footer' ? (
            <PlusIcon />
          ) : (
            <ChevronIcon
              size={type === 'filter' ? 'xs' : 's'}
              rotateDown
              transition
            />
          )}
        </IconWrapper>
      </StyledToggle>
      <StyledContent
        ref={content}
        type={type}
        css={{ maxHeight: `${setHeight}` }}
      >
        {children}
      </StyledContent>
    </StyledAccordion>
  );
}

const StyledAccordion = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid $grey300',
  variants: {
    active: {
      true: {
        background: '',
      },
    },
    topBorder: {
      true: {
        borderTop: '1px solid $grey300',
      },
    },
    type: {
      filter: {
        fontWeight: '$fw400',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
    },
  },
});

const IconWrapper = styled('div', {
  wh: 6,
  display: 'flex',
  alignItems: 'center',
  transitionDuration: timings.threeFifths,
  transitionProperty: 'all',
  variants: {
    isOpen: {
      true: {
        transform: 'rotate(-180deg)',
      },
    },
  },
});

const StyledContent = styled('div', {
  maxHeight: 0,
  overflowY: 'hidden',
  transition: 'max-height $600 ease',
  variants: {
    type: {
      filter: {
        fontWeight: '$fw400',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
        px: 0,
      },
    },
  },
});

const StyledToggle = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  py: 4.5,
  border: '0px',
  outlineStyle: 'none',
  cursor: 'pointer',
  fs: 7,
  fontWeight: '$fw700',
  '@mediaMinLarge': {
    fs: 8,
    lineHeight: '25.3px',
    py: 6,
  },
  variants: {
    type: {
      filter: {
        fontWeight: '$fw400',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '1px',
        textTransform: 'uppercase',
      },
    },
  },
});

export default Accordion;
