import React from 'react';
import { styled } from '../../stitches.config';
import { GridSize, GridWidth } from '../../Enums/GridSize.enum';
import {
  fadeInProduct,
  animationPathsIn,
} from '../../Theme/Settings/keyframes';
import { timings } from '../../Theme/Settings/animation';

type PropTypes = {
  children: React.ReactNode;
  type?: GridSize;
  width?: GridWidth;
  noPadding?: boolean;
  listViewGrid?: boolean;
};

function Grid({
  children,
  type,
  width,
  noPadding,
  listViewGrid,
  ...rest
}: PropTypes) {
  return (
    <StyledGrid
      type={type}
      size={width}
      {...rest}
      listViewGrid={listViewGrid}
      noPadding={noPadding}
    >
      {children}
    </StyledGrid>
  );
}
const StyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
  margin: 'auto',
  g: 4,
  variants: {
    type: {
      3: {
        justifySelf: 'center',
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        gg: 0,
        '@mediaMinMedium': {
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          gg: 8,
        },
      },
      4: {
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        g: 8,
        '@mediaMinSmall': {
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          g: 8,
        },
        '@mediaMinMedium': {
          gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
          g: 8,
        },
        '@mediaMinLarge': {
          gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
          g: 8,
        },
        '@mediaMinHuge': {
          gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
          g: 8,
        },
      },
      12: {
        w: '100%',
        maxW: '$contentMaxWidth',
        mx: 'auto',
        px: 4,
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
        g: 4,
        '@mediaMinLarge': {
          g: 8,
          px: 5,
          rowGap: '100px',
        },
      },
      usp: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gg: 3,
        '@mediaMinLarge': {
          gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
          gg: 3,
        },
      },
    },
    size: {
      screenWidth: {
        maxW: '$screenMaxWidth',
      },
      contentWidth: {
        maxW: '$contentMaxWidth',
      },
      auto: {
        maxW: 'auto',
      },
    },
    noPadding: {
      true: {
        px: 0,
      },
    },
    listViewGrid: {
      true: {
        animation: `${fadeInProduct} ${timings.oneHalf}`,
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: '1',
        g: 4,
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      },
      false: {
        animation: `${animationPathsIn} ${timings.oneHalf}`,
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: '1',
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
      },
    },
  },
});

export default Grid;
