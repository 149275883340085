import ContentContainer from '../../../../Molecules/ContentContainer/ContentContainer';
import Grid from '../../../../Atoms/Grids/Grid';
import { H4 } from '../../../../Atoms/Typography/Headings/Heading';
import XHtmlArea from '../../../../Molecules/XHtmlArea/XHtmlArea';
import FooterList from './FooterList/FooterList';
import useQueryFooter from './useQueryFooter';
import { styled } from '../../../../stitches.config';
import { GridSize } from '../../../../Enums/GridSize.enum';
import IFooter from './Footer.interface';
import IBottomColumn from '../../../../Shared/AutoMapInterfaces/IBottomColumn.interface';

type PropTypes = {
  initData: IFooter;
};

export function Facebook() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9995 20H23.6875L24 16.9995H23.3741H21.3195L21.3186 15.9416C21.3186 15.2282 21.2471 15.0005 22.0009 15.0005H24.0009V12H22.0009C19.3675 12 18 13.4569 18 15.6875V15.9266V17.0005H16V20H18V28H18.0414H20.9995V22.9995V20.0649V20Z"
        fill="#494948"
      />
      <circle cx="20" cy="20" r="19.5" stroke="#494948" />
    </svg>
  );
}

export function Instagram() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" stroke="#494948" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.315 12H23.685C26.0526 12 28 13.9521 28 16.3254V23.6747C28 26.0479 26.0526 28 23.685 28H16.315C13.9474 28 12 26.0479 12 23.6747V16.3254C12 13.9521 13.9474 12 16.315 12ZM24.2957 14.7944C24.7922 14.7944 25.2123 15.2155 25.2123 15.7132C25.2123 16.2109 24.7922 16.632 24.2957 16.632C23.7614 16.632 23.3791 16.2109 23.3791 15.7132C23.3791 15.2155 23.7608 14.7944 24.2957 14.7944ZM19.9808 15.6367H20.0192C22.3868 15.6367 24.3726 17.6273 24.3726 20.0006C24.3726 22.4118 22.3868 24.3644 20.0192 24.3644H19.9808C17.6132 24.3644 15.6658 22.4123 15.6658 20.0006C15.6658 17.6273 17.6132 15.6367 19.9808 15.6367ZM19.9808 17.1297H20.0192C21.585 17.1297 22.8832 18.4311 22.8832 20.0006C22.8832 21.608 21.585 22.9094 20.0192 22.9094H19.9808C18.415 22.9094 17.1168 21.608 17.1168 20.0006C17.1168 18.4311 18.415 17.1297 19.9808 17.1297ZM16.3534 13.3779H23.6466C25.2887 13.3779 26.6248 14.7179 26.6248 16.3633V23.6361C26.6248 25.2821 25.2881 26.6215 23.6466 26.6215H16.3534C14.7113 26.6215 13.3752 25.2816 13.3752 23.6361V16.3639C13.3752 14.7179 14.7119 13.3785 16.3534 13.3785V13.3779Z"
        fill="#494948"
      />
    </svg>
  );
}

export function Linkedin() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" stroke="#494948" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3137 15.3854H15.4903V26H12.3137V15.3854ZM13.8825 10C14.9414 10 15.804 10.8583 15.804 11.9121C15.804 12.9658 14.9414 13.8241 13.8825 13.8241C12.8236 13.8241 12 12.9658 12 11.9121C12 10.8583 12.8236 10 13.8825 10ZM17.3729 15.3854H20.51V16.8686C21.1768 15.8929 22.2746 15.2686 23.5298 15.2686H24.3924C26.3922 15.2686 28 16.9079 28 18.8586V26H24.8629V25.0243V20.2632C24.8233 19.0534 24.0003 18.117 22.7846 18.117C21.5689 18.117 20.5495 19.0534 20.51 20.2632V26H17.3729V15.3854Z"
        fill="#494948"
      />
    </svg>
  );
}

export function YouTube() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_412_5160)">
        <path d="M29 14H11V26.5217H29V14Z" fill="#494948" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6499 17.5949L22.3504 20.2436L17.6499 22.9269V17.5949Z"
          fill="white"
        />
      </g>
      <circle cx="20" cy="20" r="19.5" stroke="#494948" />
      <defs>
        <clipPath id="clip0_412_5160">
          <rect x="11" y="14" width="18" height="12.5217" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function FooterDesktop({ initData }: PropTypes) {
  const [
    {
      footerText,
      footerLeftColumnLinks,
      footerCenterColumnLinks,
      footerRightColumnLinks,
      footerLeftHeader,
      footerCenterHeader,
      footerRightHeader,
      footerBottom,
      facebook,
      instagram,
      linkedIn,
      youTube,
    },
  ] = useQueryFooter(initData);

  return (
    <>
      <FooterElm>
        <ContentContainer>
          <GridWrapper>
            <Grid type={GridSize.Four}>
              {footerLeftColumnLinks && !!footerLeftColumnLinks.length && (
                <div>
                  <FooterList
                    header={footerLeftHeader}
                    links={footerLeftColumnLinks}
                  />
                </div>
              )}
              {footerCenterColumnLinks && !!footerCenterColumnLinks.length && (
                <div>
                  <FooterList
                    header={footerCenterHeader}
                    links={footerCenterColumnLinks}
                  />
                </div>
              )}
              {footerRightColumnLinks && !!footerRightColumnLinks.length && (
                <div>
                  <FooterList
                    header={footerRightHeader}
                    links={footerRightColumnLinks}
                  />
                </div>
              )}
            </Grid>
          </GridWrapper>
          <Divider />
          <GridWrapper>
            <Grid type={GridSize.Four}>
              {footerBottom &&
                footerBottom.map((item: IBottomColumn, index: number) => (
                  <div key={index}>
                    {item.footerBottomHeader && (
                      <H4 css={BottomTitleStyle}>{item.footerBottomHeader}</H4>
                    )}
                    <BottomContent>
                      <XHtmlArea content={item.footerBottomText || ''} />
                    </BottomContent>
                  </div>
                ))}
            </Grid>
          </GridWrapper>
        </ContentContainer>
      </FooterElm>
      <BottomElm>
        <ContentContainer>
          <BottomContent>
            <div>{footerText && <XHtmlArea content={footerText || ''} />}</div>
            <SocialMedia>
              {youTube && (
                <li>
                  <a href={youTube} target="_blank" rel="noreferrer">
                    <YouTube />
                  </a>
                </li>
              )}
              {facebook && (
                <li>
                  <a href={facebook} target="_blank" rel="noreferrer">
                    <Facebook />
                  </a>
                </li>
              )}
              {instagram && (
                <li>
                  <a href={instagram} target="_blank" rel="noreferrer">
                    <Instagram />
                  </a>
                </li>
              )}
              {linkedIn && (
                <li>
                  <a href={linkedIn} target="_blank" rel="noreferrer">
                    <Linkedin />
                  </a>
                </li>
              )}
            </SocialMedia>
          </BottomContent>
        </ContentContainer>
      </BottomElm>
    </>
  );
}

const BottomTitleStyle = {
  fontWeight: '$fw400',
  fontSize: '12px',
  lineHeight: '24px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
};

const BottomContent = styled('div', {
  fontWeight: '$fw400',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const SocialMedia = styled('ul', {
  display: 'flex',
  listStyleType: 'none',
  margin: '0',
  padding: '0',
  fontsize: '0px',
  gap: '15px',
});

const FooterElm = styled('footer', {
  backgroundColor: '#E7DDD8',
  marginTop: 'auto',
  py: 12,
  px: '20px',
});

const BottomElm = styled('div', {
  backgroundColor: '#F1EBE8',
  marginTop: 'auto',
  py: 3,
  px: '20px',
});

const GridWrapper = styled('div', {
  px: 0,
});

const Divider = styled('div', {
  w: '100%',
  h: 0.5,
  backgroundColor: '#000',
  mb: '50px',
  mt: '50px',
});
