import React from 'react';
import { useKexNavigate } from './KexRouter/KexRouter';
import {
  isInternalLink,
  isEmailLink,
  hasFileExtension,
} from '../Shared/Common/Helpers';
import { styled } from '../stitches.config';
import { KexLinkBase } from './BaseKexLinkStyle';

type RedirectType = {
  kexNavigate: any;
  target: string;
  url: string;
  onNavigated?: () => void;
};

type PropType = {
  onClick?: (x?: () => void) => void;
  css?: any;
  callbackOnClick?: boolean;
  onNavigated?: () => void;
  children?: React.ReactNode;
  href?: string;
  title?: string;
  type?: 'primary' | 'secondary';
  noUnderline?: boolean;
  size?: string;
  navType?: string;
  target?: string;
};

const redirect = ({
  kexNavigate,
  target,
  url = '',
  onNavigated,
}: RedirectType) => {
  if (url === '' || url === undefined) return;
  if (
    target !== '_blank' &&
    isInternalLink({ url }) &&
    !hasFileExtension({ url })
  ) {
    onNavigated ? kexNavigate(url).then(onNavigated()) : kexNavigate(url);
  } else if (isEmailLink({ url })) {
    window.location.href = url;
  } else {
    window.open(url, '_blank');
  }
};

const KexLink = ({
  onClick,
  css,
  callbackOnClick,
  onNavigated,
  children,
  href,
  type,
  noUnderline,
  size,
  navType,
  target,
  ...rest
}: PropType) => {
  const kexNavigate = useKexNavigate();

  return typeof href === 'string' && href.substr(0, 4) === 'http' ? (
    <StyledA {...rest} target={target} href={href} css={css}>
      {children}
    </StyledA>
  ) : (
    <StyledA
      color={type}
      target={target}
      noUnderline={noUnderline}
      {...rest}
      onClick={(e: any) => {
        e.persist();
        e.preventDefault();
        const target = e.currentTarget.target;
        if (callbackOnClick) {
          onClick &&
            onClick(() =>
              redirect({ kexNavigate, target, url: href ?? '', onNavigated })
            );
        } else {
          onClick && onClick();
          redirect({ kexNavigate, target, url: href ?? '', onNavigated });
        }
      }}
      href={href}
      size={size}
    >
      {children}
    </StyledA>
  );
};

const StyledA = styled(KexLinkBase, {});

export default KexLink;
