import { styled } from '../../stitches.config';
import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

type PropType = IconBaseType & {
  isOpen?: boolean;
};

const PlusIcon = ({
  css,
  color,
  size,
  isOpen = false,
  onBackground,
  noMargin,
}: PropType) => {
  return (
    <StyledSvg
      size={size}
      color={color}
      css={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      noMargin={noMargin}
    >
      <SryledG isOpen={isOpen}>
        <StyledPath
          onBackground={onBackground}
          d="M8 0a.5.5 0 01.5.5v7h7a.5.5 0 010 1h-7v7a.5.5 0 01-1 0v-7h-7a.5.5 0 010-1h7v-7A.5.5 0 018 0z"
        />
      </SryledG>
    </StyledSvg>
  );
};

export default PlusIcon;

const SryledG = styled('g', {
  transformOrigin: 'center',
  transitionDuration: '$200',
  transitionproperty: 'transform',
  variants: {
    isOpen: {
      true: {
        transform: 'rotate3d(0, 0, 1, 45deg)',
        transformOrigin: 'center',
      },
    },
  },
});

const StyledSvg = styled('svg', {
  ...BaseIconStyle,
  objectFit: 'contain',
});

const StyledPath = styled('path', {
  ...BaseIconPathStyle,
  fill: 'inherit',
  fillRule: 'nonzero',
});
