/** 
  Spacings helper to create spacings with base as 4. Kex-default = 4px. example: 40px padding = theme.spacing(10),
**/

const BASE_SPACING = 4;
export const spacing = (multiplier: number) => BASE_SPACING * multiplier;
//Used for calculations
export const SizeConstants = {
  heroMaxHeight: 500,
  videoMaxHeight: 574,
  modalHeight: 736,
  standardBlockLargerImageHeight: 490,
  standardBlockLargeImageHeight: 480,
  searchModalHeight: 472,
  standardBlockMediumImageHeight: 345,
  thirdWidthBlockImageHeight: 312,
  standardBlockSmallImageHeight: 250,
  verySmallBlockImageHeight: 180,
  mobileHeaderHeight: 108,

  //widths
  screenMaxWidth: '100vw',
  desktopSearchModalMaxWidth: 'calc(100vw - 64px)',
  contentMaxWidth: 1240,
  contentMaxWidthSmall: 864,
  videoMaxWidth: 1024,
  maxWidthSmall: 960,
  contentResultWidth: 864,
  maxWidthMobile: 768,
  blockMaxWidthMobile: 600,
  mobileMenuMaxWidth: 287,

  //imageHeight
  thumbnailImageHeight: 128,
  productImageMobileMaxHeight: 375,
  productImageMaxHeight: 476,
  productImageMaxHeightMedium: 600,
  productImageMaxHeightLarge: 700,
};

//Used in stitches
const widths = {
  screenMaxWidth: `${SizeConstants.screenMaxWidth}`,
  contentMaxWidth: `${SizeConstants.contentMaxWidth}px`,
  desktopSearchModalMaxWidth: `${SizeConstants.desktopSearchModalMaxWidth}`,
  contentMaxWidthSmall: `${SizeConstants.contentMaxWidthSmall}px`,
  videoMaxWidth: `${SizeConstants.videoMaxWidth}px`,
  maxWidthSmall: `${SizeConstants.maxWidthSmall}px`,
  contentResultWidth: `${SizeConstants.contentResultWidth}px`,
  maxWidthMobile: `${SizeConstants.maxWidthMobile}px`,
  blockMaxWidthMobile: `${SizeConstants.blockMaxWidthMobile}px`,
  mobileMenuMaxWidth: `${SizeConstants.mobileMenuMaxWidth}px`,
};

const imageHeights = {
  thumbnailImageHeight: `${SizeConstants.thumbnailImageHeight}px`,
  productImageMaxHeight: `${SizeConstants.productImageMaxHeight}px`,
  productImageMobileMaxHeight: `${SizeConstants.productImageMobileMaxHeight}px`,
  productImageMaxHeightMedium: `${SizeConstants.productImageMaxHeightMedium}px`,
  productImageMaxHeightLarge: `${SizeConstants.productImageMaxHeightLarge}px`,
};

const heights = {
  heroMaxHeight: `${SizeConstants.heroMaxHeight}px`,
  videoMaxHeight: `${SizeConstants.videoMaxHeight}px`,
  modalHeight: `${SizeConstants.modalHeight}px`,
  standardBlockLargerImageHeight: `${SizeConstants.standardBlockLargerImageHeight}px`,
  standardBlockLargeImageHeight: `${SizeConstants.standardBlockLargeImageHeight}px`,
  searchModalHeight: `${SizeConstants.searchModalHeight}px`,
  standardBlockMediumImageHeight: `${SizeConstants.standardBlockMediumImageHeight}px`,
  thirdWidthBlockImageHeight: `${SizeConstants.thirdWidthBlockImageHeight}px`,
  standardBlockSmallImageHeight: `${SizeConstants.standardBlockSmallImageHeight}px`,
  verySmallBlockImageHeight: `${SizeConstants.verySmallBlockImageHeight}px`,
  mobileHeaderHeight: `${SizeConstants.mobileHeaderHeight}px`,
};

export default { ...widths, ...heights, ...imageHeights };
