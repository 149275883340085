import { timings } from '../../Theme/Settings/animation';
import {
  animationLoadingStrokeDash,
  animationRotation,
} from '../../Theme/Settings/keyframes';
import { styled } from '../../stitches.config';

export const BaseIconStyle = styled('svg', {
  position: 'absolute',
  top: 'calc(50% - 25px)',
  right: 'calc(50% - 25px)',
  variants: {
    isLoading: {
      true: {
        animation: `${animationRotation}`,
        animationDuration: timings.one,
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
      },
    },
    size: {
      normal: {
        w: 4,
        h: 5,
      },
      large: {
        w: 12.5,
        h: 12.5,
      },
    },
  },
  defaultVariants: {
    size: 'normal',
  },
});

export const BaseIconPathStyle = styled('path', {
  variants: {
    color: {
      primary: {
        stroke: '$textPrimary',
      },
      secondary: {
        stroke: '$textSecondary',
      },
      accent: {
        color: '$accent',
      },
      error: {
        stroke: '$errorText',
      },
    },
  },
  defaultVariants: {
    color: 'primary',
  },
});

export const BaseIconCircleStyle = styled('circle', {
  stroke: '$primary',
  strokeLinecap: 'round',
  variants: {
    color: {
      primary: {
        stroke: '$textPrimary',
      },
      secondary: {
        stroke: '$textSecondary',
      },
      accent: {
        stroke: '$accent',
      },
      error: {
        stroke: '$errorText',
      },
    },
    isLoading: {
      true: {
        animation: `${animationLoadingStrokeDash}`,
        animationDuration: timings.oneAndAHalf,
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
      },
    },
  },
  defaultVariants: {
    color: 'primary',
  },
});
