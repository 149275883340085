import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function HomeIcon({ css, color, size, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      color={color}
      size={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 10"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="nonzero"
        d="M9.5 6c.245 0 .45.177.492.41L10 6.5v4c0 .78-.595 1.42-1.356 1.493L8.5 12h-5c-.78 0-1.42-.595-1.493-1.356L2 10.5v-4c0-.276.224-.5.5-.5.245 0 .45.177.492.41L3 6.5v4c0 .245.177.45.41.492L3.5 11h5c.245 0 .45-.177.492-.41L9 10.5v-4c0-.276.224-.5.5-.5zM6 9c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1zM6.26.073l.076.057 5.5 5c.205.186.22.502.034.706-.165.182-.433.214-.634.088l-.072-.054L6 1.175.836 5.87c-.181.165-.451.172-.64.027l-.066-.06c-.165-.182-.172-.452-.027-.64l.06-.067 5.5-5c.168-.152.411-.17.598-.057z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default HomeIcon;
