type keyValueType = {
  [key: string]: any;
};

//New const for stitches and eseMedia
//Add when needed
export const mediaQueryTypes = {
  mediaMaxVerySmall: '(max-width: 500px)', //Rename
  mediaMinVerySmall: '(min-width: 500px)', //Rename
  mediaMaxSmall: '(max-width: 600px)', //Rename
  mediaMinSmall: '(min-width: 600px)', //Rename
  mediaMaxMedium: '(max-width: 768px)', //Rename
  mediaMinMedium: '(min-width: 768px)', //Rename
  mediaMaxLarge: '(max-width: 992px)', //Rename
  mediaMinLarge: '(min-width: 992px)', //Rename
  mediaMaxHuge: '(max-width: 1200px)', //Rename
  mediaMinHuge: '(min-width: 1200px)', //Rename
  mediaMinGreat: '(min-width: 1300px)',
};

export function mediaQuerySorter(a: string, b: string) {
  var typedMediaQueries = mediaQueryTypes as keyValueType;
  const mediaQueryArray = Object.keys(typedMediaQueries).map(
    (key) => typedMediaQueries[key]
  );
  const indexA = mediaQueryArray.indexOf(a);
  const indexB = mediaQueryArray.indexOf(b);

  if (indexA === indexB) return 0;
  return indexA > indexB ? 1 : -1;
}
