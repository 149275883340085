import React from 'react';
import { styled } from '../../../stitches.config';
import { BaseParagraphStyle } from './BaseParagraphStyle';

type ParagraphProps = {
  size?: 's' | 'xs' | undefined;
  children: React.ReactNode;
};

function Paragraph({ children, size, ...rest }: ParagraphProps) {
  return (
    <StyledParagraph size={size} {...rest}>
      {children}
    </StyledParagraph>
  );
}
const StyledParagraph = styled('p', BaseParagraphStyle);

export default Paragraph;
