import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import KexLink from '../../Kex/KexLink';
import { styled } from '../../stitches.config';
import React from 'react';
import { StructuredBreadcrumbData } from '../StructuredData';

function Breadcrums() {
  const { breadcrumb } = useCurrentPage<PageModelBase>();

  return (
    <BreadcrumbDiv>
      <BreadcrumbContainer>
        <StructuredBreadcrumbData breadcrumbs={breadcrumb} />
        {breadcrumb.map((link, index) => (
          <React.Fragment key={index}>
            {index !== breadcrumb.length - 1 ? (
              <>
                <BreadcrumbLink key={index} href={link.href}>
                  {link.text}
                </BreadcrumbLink>
                <Seperator> {' > '} </Seperator>
              </>
            ) : (
              <>
                <SelectedCrumb>{link.text}</SelectedCrumb>
              </>
            )}
          </React.Fragment>
        ))}
      </BreadcrumbContainer>
    </BreadcrumbDiv>
  );
}

const BreadcrumbDiv = styled('div', {
  h: 12,
  alignItems: 'center',
  display: 'flex',
  backgroundColor: '$white',
  '@mediaMaxSmall': {
    mt: 1,
  },
});

const BreadcrumbContainer = styled('div', {
  w: '100%',
  maxWidth: '$contentMaxWidth',
  m: 'auto',
  px: '20px',
  h: 4,
  display: 'flex',
});

const SelectedCrumb = styled('span', {
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '1px',
});

const BreadcrumbLink = styled(KexLink, {
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '1px',
});

const Seperator = styled('span', {
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '1px',
  px: '5px',
  top: '-1px',
  position: 'relative',
});

export default Breadcrums;
