import React from 'react';
import { StructuredData } from './StructuredData';
import { Organization, WithContext } from 'schema-dts';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';

function OrganizationStructuredData(): JSX.Element {
  const { description, logoType, domain } = useAppSettingsData();

  const data: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: domain,
    logo: `${domain}${logoType?.src}`,
    name: 'MerxTeam AB',
    description: description,
    email: 'info@merxteam.com',
    telephone: '+4631506700',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Galvaniseringsgatan 5',
      addressLocality: 'Göteborg',
      addressCountry: 'SE',
      postalCode: '41707',
    },
    vatID: 'SE556184855601',
  };

  return <StructuredData json={data} />;
}

export { OrganizationStructuredData };
