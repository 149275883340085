import React, {
  useEffect,
  useCallback,
  useReducer,
  useRef,
  useMemo,
} from 'react';

import { useSaveScrollPosition, updateScrollPosition } from './ScrollHandling';
import useQueryPage from './useQueryPage';
import AppInitModel from '../../Shared/Models/AppInitModel.interface';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';

type PropType = {
  appInitData: AppInitModel;
  initUrl: string;
  children: React.ReactNode;
};

export type KexNavigateEventStateType = PopStateEvent & {
  clicked?: true;
  id?: number;
};

export type KexNavigateType = (
  url: string,
  event?: PopStateEvent | undefined
) => Promise<PageModelBase>;

type kexNavigateCallbackRefType = {
  resolve: (data: any) => any;
  reject: (data: any) => any;
  eventState: KexNavigateEventStateType;
};

const KexRouterDispatchContext = React.createContext({});
const KexRouterCurrentPage = React.createContext({});

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'updateUrl': {
      return { ...state, url: action.url };
    }
    default: {
      return state;
    }
  }
};
let hasMounted = false;

function KexRouter({ appInitData, initUrl, children }: PropType) {
  const [routerState, dispatchState] = useReducer(reducer, {
    url: initUrl,
  });
  const kexNavigateCallbackRef = useRef<kexNavigateCallbackRefType>();
  const [currentPageData, pageId, pageTitle] = useQueryPage(
    routerState.url,
    appInitData
  );

  const kexNavigate = useCallback(
    (url: string, event?: PopStateEvent): Promise<PageModelBase> => {
      const { state } = event ? event : { state: { clicked: true } };
      return new Promise((resolve, reject) => {
        kexNavigateCallbackRef.current = {
          resolve,
          reject,
          eventState: state,
        };
        dispatchState({
          type: 'updateUrl',
          url: url,
        });
      });
    },
    []
  );

  useSaveScrollPosition(kexNavigate);

  useEffect(() => {
    document.title = `${pageTitle}`;
  }, [pageTitle]);

  /**
   * using useMemo instead of useEffect because we need to updateScrollPosition (change url)
   * before we render the new current page.
   */
  useMemo(() => {
    if (hasMounted) {
      if (kexNavigateCallbackRef.current) {
        if (currentPageData.responseUrl) {
          updateScrollPosition(
            kexNavigateCallbackRef.current.eventState,
            currentPageData.responseUrl,
            pageTitle
          );
          kexNavigateCallbackRef.current = undefined;
        }
      }
    } else {
      hasMounted = true;
    }
    /* we only want to trigger updateScrollPosition the first time we get data from
     * useQueryPage and it's safe to only use pageId as dependency.
     */
    // eslint-disable-next-line
  }, [pageId, currentPageData.responseUrl]);

  return (
    <KexRouterCurrentPage.Provider value={currentPageData}>
      <KexRouterDispatchContext.Provider value={kexNavigate}>
        {children}
      </KexRouterDispatchContext.Provider>
    </KexRouterCurrentPage.Provider>
  );
}

const useKexNavigate = () => {
  return React.useContext(KexRouterDispatchContext) as KexNavigateType;
};

function useKexRouterCurrentPage() {
  return React.useContext(KexRouterCurrentPage) as unknown;
}

export { KexRouter, useKexNavigate, useKexRouterCurrentPage };
