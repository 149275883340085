import { styled } from '../../stitches.config';
import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function EditIcon({ css, color, onBackground, size }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      size={size}
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        d="M13 6L15 4L11 0L9 2L8 3L1 10V14H5L12 7L13 6ZM12.2924 5.29243L13.585 4L11 1.415L9.70743 2.70743L12.2924 5.29243ZM8.70737 3.70737L2 10.414V13H4.584L11.2924 6.29237L8.70737 3.70737ZM0 16V15H16V16H0Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default EditIcon;
