import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function PackageIcon({ css, size, color, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        d="M16.2058 0.0443147L16.1253 0.0159533C16.0158 -0.0124081 15.8989 -0.00295431 15.7942 0.0443147L0.273758 7.05399L0.230924 7.0785L0.191097 7.10681L0.142861 7.14864L0.0873132 7.21563L0.0468739 7.28644L0.0340593 7.31825L0.00982141 7.40089C0.00337991 7.43293 0 7.46607 0 7.5V24.5L0.00689856 24.5829C0.0341947 24.7455 0.140696 24.8864 0.294207 24.9557L15.8069 31.9613L15.8838 31.9867L15.9295 31.9955L16.0195 32.0002L16.0937 31.9916L16.1609 31.9735L16.2058 31.9557L31.7058 24.9557C31.8849 24.8748 32 24.6965 32 24.5V7.5L31.9943 7.42395L31.9829 7.36872L31.9531 7.28648L31.9296 7.24172C31.919 7.22398 31.9072 7.2068 31.8932 7.1911L31.8571 7.1486L31.8099 7.10671L31.7481 7.06579L16.2058 0.0443147ZM1 24.177V8.274L15.5 14.822V30.725L1 24.177ZM31 8.273L16.5 14.822V30.725L31 24.177V8.273ZM9.465 3.999L16 1.048L30.285 7.499L16 13.951L1.716 7.499L8.249 4.548L22.533 10.999L23.748 10.45L9.465 3.999Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default styled(PackageIcon);
