import { globalCss } from '@stitches/react';

const globalStyles = globalCss({
  html: {
    lineHeight: 1.15,
    webkitTextSizeAdjust: '100%',
    fontFamily:
      "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    fontSize: '14px',
    height: '100%',
    background: 'white',
    overflowY: 'scroll',
  },
  body: {
    margin: 0,
    padding: 0,
    fontFamily: 'arial',
    webkitFontSmoothing: 'antialiased',
    mozOsxFontSmoothing: 'grayscale',
    color: '$black',
    overflowX: 'hidden',
    minHeight: '100%',
  },
  main: {
    display: 'block',
  },
  h1: {
    fontSize: '2em',
    margin: '0.67em 0',
  },
  hr: {
    boxSizing: 'content-box',
    borderTopWidth: '1px',
    height: 0,
    overflow: 'visible',
  },
  pre: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  a: {
    backgroundColor: 'transparent',
    color: 'inherit',
  },
  'abbr[title]': {
    borderBottom: 'none',
    webkitTextDecoration: 'underline dotted',
    textDecoration: 'underline dotted',
  },
  b: {
    fontSeight: 'bolder',
  },
  strong: {
    fontSeight: 'bolder',
  },
  code: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  kbd: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  samp: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  small: {
    fontSize: '80%',
  },
  sub: {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
    bottom: '-0.25em',
  },
  sup: {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
    top: '-0.5em',
  },
  img: {
    borderStyle: 'solid',
  },
  'button, input, optgroup, select, textarea': {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: 'inherit',
    margin: 0,
    padding: 0,
    color: 'inherit',
  },
  'button, input': {
    /* 1 */
    overflow: 'visible',
  },
  'button, select': {
    textTransform: 'none',
  },
  'button, [type="button"], [type="reset"], [type="submit"]': {
    webkitAppearance: 'button',
  },
  'button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner':
    {
      borderStyle: 'none',
      padding: 0,
    },
  'button:-moz-focusring, [type="button"]":-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring':
    {
      outline: '1px dotted ButtonText',
    },
  fieldset: {
    padding: '0.35em 0.75em 0.625em',
    margin: 0,
  },
  legend: {
    boxSizing: 'border-box',
    color: 'inherit',
    display: 'table',
    maxWidth: '100%',
    padding: 0,
    whiteSpace: 'normal',
  },
  progress: {
    verticalAlign: 'baseline',
  },
  textarea: {
    overflow: 'auto',
    resize: 'vertical',
  },
  '[type="checkbox"], [type="radio"]': {
    boxSizing: 'border-box',
    padding: 0,
  },
  '[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button':
    {
      height: 'auto',
    },
  '[type="search"]': {
    webkitAppearance: 'textfield',
    outlineOffset: '-2px',
  },
  '[type="search"]::-webkit-search-decoration': {
    webkitAppearance: 'none',
  },
  '::-webkit-file-upload-button': {
    webkitAppearance: 'button',
    font: 'inherit',
  },
  details: {
    display: 'block',
  },
  summary: {
    display: 'list-item',
  },
  template: {
    display: 'none',
  },
  '[hidden]': {
    display: 'none',
  },
  'blockquote, dl, dd, h4, h5, h6, hr, figure': {
    margin: 0,
  },
  button: {
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    padding: 0,
  },
  'ol, ul': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  '*, ::before, ::after': {
    boxSizing: 'border-box',
    borderWidth: 0,
    borderStyle: 'solid',
    borderColor: '#e2e8f0',
  },
  'input:-ms-input-placeholder, textarea:-ms-input-placeholder': {
    color: '#a0aec0',
  },
  'input::-ms-input-placeholder, textarea::-ms-input-placeholder': {
    color: '#a0aec0',
  },
  'input::placeholder, textarea::placeholder': {
    color: '#a0aec0',
  },
  'button, [role="button"]': {
    cursor: 'pointer',
  },
  table: {
    borderCollapse: 'collapse',
  },
  '.rootlocked': {
    position: 'fixed',
    left: 0,
    right: 0,
    display: 'block',
    height: 'auto',
  },
  '.reactHorizontalScroll': {
    width: '100%',
  },
  '.react-horizontal-scrolling-menu--scroll-container': {
    paddingBottom: '16px',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '@media (min-width: 992px)': {
      paddingBottom: 0,
      overflowX: 'hidden',
    },
  },
  '.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging':
    {
      boxShadow: '0 0 0 1px #484848 !important',
    },
  'h1, h2, h3, h4, h5, h6': {
    fontWeight: 'inherit',
  },
  'pre, code, kbd, samp': {
    fontFamily:
      'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  },
  'img, svg, video, canvas, audio, iframe, embed, object': {
    display: 'block',
    verticalAlign: 'middle',
  },
  'img, video': {
    maxWidth: '100%',
    height: 'auto',
  },
  'html.wf-active body': {
    fontFamily:
      'articulat-cf, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen-Sans, Ubuntu, Cantarell, sans-serif',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '1px',
  },
  'body:not(.ie) #root': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  'body:not(.ie) main': {
    flexGrow: 1,
    width: '100%',
  },
  '#root': {
    overflowX: 'hidden',
  },
  ':focus:not(:focus-visible)': {
    outline: 'none',
  },

  '@media (min-width: 768px)': {
    html: {
      fontSize: '16px',
    },
  },
  '@media print': {
    'body:not(.IE) #root': {
      display: 'block',
    },
  },
});

export default globalStyles;
