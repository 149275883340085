import { parseUrl } from '../../Shared/Common/Helpers';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import Fetcher from '../../Shared/Common/Fetcher';
import AbortControllerRefType from '../../Shared/Common/AbortControllerRefType';

export interface ReturnPageModel extends PageModelBase {
  responseUrl?: string;
}

function FetchPage(
  url: string,
  abortControllerRef: React.MutableRefObject<AbortControllerRefType>
) {
  abortControllerRef.current?.abortController?.abort();
  abortControllerRef.current = {
    url,
    abortController: new AbortController(),
  };
  const signal = abortControllerRef.current.abortController.signal;

  if (url === 'epiedit') {
    url = window.location.pathname + window.location.search;
  }

  /*
   * fix so we are only fetching the current page and not the whole appinitdata
   */
  return Fetcher<ReturnPageModel, any>(
    url,
    signal,
    (data, resolve, reject, requestResult) => {
      // if requested url is the same as response page, add, if present, hash to response url if
      // requested url had hash.
      const responseUrl = requestResult.url;
      const parsedUrl = parseUrl(url);
      const parsedResUrl = parseUrl(responseUrl);
      data.currentPage.responseUrl =
        parsedUrl.pathname + parsedUrl.search ===
        parsedResUrl.pathname + parsedResUrl.search
          ? parsedUrl.hash
            ? `${responseUrl}${parsedUrl.hash}`
            : responseUrl
          : responseUrl;
      resolve(data.currentPage);
    }
  );
}

export default FetchPage;
