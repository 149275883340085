import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function AttentionIcon({
  css,
  size,
  color,
  space,
  onBackground,
}: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16ZM1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16ZM16 26C16.5523 26 17 25.5523 17 25V13C17 12.4477 16.5523 12 16 12C15.4477 12 15 12.4477 15 13V25C15 25.5523 15.4477 26 16 26ZM17 9C17 9.55229 16.5523 10 16 10C15.4477 10 15 9.55229 15 9V7C15 6.44772 15.4477 6 16 6C16.5523 6 17 6.44772 17 7V9Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default styled(AttentionIcon);
