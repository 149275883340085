import ContentContainer from '../../../../Molecules/ContentContainer/ContentContainer';
import Grid from '../../../../Atoms/Grids/Grid';
import { H4 } from '../../../../Atoms/Typography/Headings/Heading';
import XHtmlArea from '../../../../Molecules/XHtmlArea/XHtmlArea';
import FooterList from './FooterList/FooterList';
import useQueryFooter from './useQueryFooter';
import { styled } from '../../../../stitches.config';
import { GridSize } from '../../../../Enums/GridSize.enum';
import IFooter from './Footer.interface';
import Accordion from '../../../../Molecules/Accordion/Accordion';
import { Facebook, Instagram, Linkedin, YouTube } from './FooterDesktop';
import IBottomColumn from '../../../../Shared/AutoMapInterfaces/IBottomColumn.interface';

type PropTypes = {
  initData: IFooter;
};

export function FooterMobile({ initData }: PropTypes) {
  const [
    {
      footerText,
      footerLeftColumnLinks,
      footerCenterColumnLinks,
      footerRightColumnLinks,
      footerLeftHeader,
      footerCenterHeader,
      footerRightHeader,
      footerBottom,
      facebook,
      instagram,
      linkedIn,
      youTube,
    },
  ] = useQueryFooter(initData);

  return (
    <Footer>
      <FooterElm>
        <ContentContainer>
          <Accordion title={footerLeftHeader} type="footer">
            {footerLeftColumnLinks && !!footerLeftColumnLinks.length && (
              <div>
                <FooterList links={footerLeftColumnLinks} />
              </div>
            )}
          </Accordion>
          <Accordion title={footerCenterHeader} type="footer">
            {footerCenterColumnLinks && !!footerCenterColumnLinks.length && (
              <div>
                <FooterList links={footerCenterColumnLinks} />
              </div>
            )}
          </Accordion>
          {footerRightColumnLinks && !!footerRightColumnLinks.length && (
            <Accordion title={footerRightHeader} type="footer">
              <div>
                <FooterList links={footerRightColumnLinks} />
              </div>
            </Accordion>
          )}
          <GridWrapper>
            <Grid type={GridSize.Four}>
              {footerBottom &&
                footerBottom.map((item: IBottomColumn, index: number) => (
                  <div>
                    {item.footerBottomHeader && (
                      <H4 css={BottomTitleStyle}>{item.footerBottomHeader}</H4>
                    )}
                    <BottomContent>
                      <XHtmlArea content={item.footerBottomText || ''} />
                    </BottomContent>
                  </div>
                ))}
            </Grid>
          </GridWrapper>
        </ContentContainer>
      </FooterElm>
      <BottomElm>
        <ContentContainer>
          <BottomContent>
            <div>{footerText && <XHtmlArea content={footerText || ''} />}</div>
            <SocialMedia>
              {youTube && (
                <li>
                  <a href={youTube} target="_blank">
                    <YouTube />
                  </a>
                </li>
              )}
              {facebook && (
                <li>
                  <a href={facebook} target="_blank">
                    <Facebook />
                  </a>
                </li>
              )}
              {instagram && (
                <li>
                  <a href={instagram} target="_blank">
                    <Instagram />
                  </a>
                </li>
              )}
              {linkedIn && (
                <li>
                  <a href={linkedIn} target="_blank">
                    <Linkedin />
                  </a>
                </li>
              )}
            </SocialMedia>
          </BottomContent>
        </ContentContainer>
      </BottomElm>
    </Footer>
  );
}

const BottomTitleStyle = {
  fontWeight: '$fw400',
  fontSize: '12px',
  lineHeight: '24px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
};

const GridWrapper = styled('div', {
  px: 0,
  marginTop: '40px',
});

const BottomContent = styled('div', {
  fontWeight: '$fw400',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
  display: 'flex',
  flex: '1',
  flexWrap: 'wrap',
  '& p': {
    margin: '0',
  },
  '& > div': {
    '&:first-of-type': {
      marginBottom: '40px',
    },
    width: '100%',
  },
});

const SocialMedia = styled('ul', {
  display: 'flex',
  listStyleType: 'none',
  margin: '0',
  padding: '0',
  fontsize: '0px',
  gap: '15px',
  marginBottom: '24px',
});

const Footer = styled('footer', {
  backgroundColor: '#E7DDD8',
});

const FooterElm = styled('div', {
  backgroundColor: '#E7DDD8',
  marginTop: 'auto',
  py: 12,
});

const BottomElm = styled('div', {
  backgroundColor: '#F1EBE8',
  marginTop: 'auto',
  py: 3,
  marginBottom: '72px',
});
