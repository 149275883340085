import GetStandardHeaderModel from '../../App/Models/GetStandardHeaderModel.interface';
import IHeader from '../../Shared/Models/Headers/Header.interface';
import Fetcher from '../../Shared/Common/Fetcher';

let abortController: AbortController = new AbortController();

function FetchHeader(url: string, litiumContext: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  return Fetcher<IHeader, GetStandardHeaderModel>(
    url,
    signal,
    (res, resolve, reject) => {
      if (res.success) {
        resolve(res.data);
      }
    },
    litiumContext
  );
}

export default FetchHeader;
