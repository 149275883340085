import { useState, useEffect, useRef } from 'react';
import useSWR from 'swr';
import FetchPage, { ReturnPageModel } from './FetchPage';
import { useKexLoadingCircle } from '../../Kex/KexLoadingCircle';
import { IS_PRODUCTION_ENV } from '../../Shared/Configs/EnvConfig';
import AppInitModel from '../../Shared/Models/AppInitModel.interface';
import usePrevious from '../../Shared/Hooks/usePrevious';
import AbortControllerRefType from '../../Shared/Common/AbortControllerRefType';

let hasMounted = false;

export default function (
  url: string,
  initialData: AppInitModel
): [ReturnPageModel, number, string] {
  const [currentPage, setCurrentPage] = useState<ReturnPageModel>(
    initialData.currentPage
  );

  const dispatchLoading = useKexLoadingCircle();
  const abortControllerRef = useRef<AbortControllerRefType>();

  if (abortControllerRef.current && abortControllerRef.current.url !== url) {
    abortControllerRef.current.abortController.abort();
  }
  const { data: fetchedPage } = useSWR<ReturnPageModel>(
    url ? [url, abortControllerRef] : null,
    FetchPage,
    {
      initialData: hasMounted ? undefined : initialData.currentPage,
      revalidateOnFocus: IS_PRODUCTION_ENV,
      dedupingInterval: initialData.appSettings.pageCacheTime,
    }
  );
  const prevFetchedPage = usePrevious<ReturnPageModel | undefined>(fetchedPage);

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (
        (!prevFetchedPage && fetchedPage) ||
        (prevFetchedPage && !fetchedPage)
      ) {
        dispatchLoading(!fetchedPage ? 'add' : 'remove');
      }

      if (fetchedPage) {
        setCurrentPage(fetchedPage);
      }
    }
  }, [dispatchLoading, fetchedPage, prevFetchedPage]);

  return [currentPage, currentPage.pageId, currentPage.pageTitle];
}
