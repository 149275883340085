import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { useKexLoadingCircle } from '../../../../Kex/KexLoadingCircle';
import { IS_PRODUCTION_ENV } from '../../../Configs/EnvConfig';
import useCurrentPage from '../../../Hooks/useCurrentPage';
import { useAppSettingsData } from '../../../Providers/AppSettingsProvider';
import FetchFooter from './FetchFooter';
import IFooter from './Footer.interface';

let hasMounted = false;
let litiumContext = '';

const useQueryFooter = function (initialData: IFooter): [IFooter] {
  const [data, setData] = useState<IFooter>(initialData);
  const kexLoadingCircleDispatch = useKexLoadingCircle();
  const { languageRoute } = useAppSettingsData();
  const { pageId, channelId } = useCurrentPage();

  litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    channelSystemId: channelId,
  });

  let url = `/api/${languageRoute || 'en'}/app/GetStandardFooter`;

  const queryData = useSWR<IFooter>(
    `/api/en/app/GetStandardFooter`,
    () => FetchFooter(url, litiumContext),
    {
      fallbackData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    kexLoadingCircleDispatch(!queryData.data ? 'add' : 'remove');
  }, [kexLoadingCircleDispatch, queryData.data]);

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
};

export default useQueryFooter;
