const variations = {
  variants: {
    noMargin: {
      true: {
        margin: 'unset',
      },
    },
    color: {
      p: {
        color: '$textPrimay',
      },
      white: {
        color: '$textSecondary',
      },
      a: {
        color: '$textAccent',
      },
    },
    center: {
      true: {
        margin: 'auto',
      },
    },
    divider: {
      true: {
        '&:after': {
          position: 'relative',
          display: 'flex',
          l: 0,
          t: 2,
          h: 0.25,
          w: 16,
          content: '""',
          borderTop: 'solid 1px $textPrimary',
        },
      },
    },
  },
};

export const BaseH1Style = {
  ...variations,
  fontSize: '60px',
  my: 4,
  lineHeight: '72px',
  fontWeight: '$fw600',
  ls: '$ls1',
  '@mediaMaxMedium': {
    fontSize: '36px',
    lineHeight: '45px',
  },
};

export const BaseH2Style = {
  ...variations,
  fontSize: '32px',
  mb: 4,
  lineHeight: '40px',
  fontWeight: '$fw600',
  ls: '1px',
  '@mediaMaxMedium': {
    fontSize: '24px',
    lineHeight: '32px',
  },
};

export const BaseH3Style = {
  ...variations,
  fontSize: '24px',
  mb: 4,
  lineHeight: '32px',
  fontWeight: '$fw600',
  ls: '1px',
  '@mediaMaxMedium': {
    fontSize: '20px',
    lineHeight: '28px',
  },
};

export const BaseH4Style = {
  ...variations,
  fontSize: '20px',
  lineHeight: '24px',
  fontWeight: '$fw600',
  ls: '1px',
  '@mediaMaxMedium': {
    fontSize: '18px',
    lineHeight: '26px',
  },
};

export const BaseH5Style = {
  ...variations,
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: '$fw600',
  ls: '1px',
  '@mediaMaxMedium': {
    fontSize: '16px',
    lineHeight: '24px',
  },
};

export const BaseH6Style = {
  ...variations,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '$fw600',
  ls: '1px',
  '@mediaMaxMedium': {
    fontSize: '14px',
    lineHeight: '23px',
  },
};
