export const BaseListStyle = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
};

export const BaseListItemStyle = {
  fs: 8,
  display: 'flex',
  alignItems: 'flex-start',
  ls: '$ls1',
  fontWeight: '$fw400',
  lineHeight: '$lh175',
  '&:not(:last-child)': {
    mb: 4,
  },
  '&::before': {
    display: 'inline-block',
    content: '',
    w: 4,
    height: 1,
    backgroundColor: '$listItemBackgroundPrimary',
    mr: 2,
    mt: 3.5,
  },
  '@mediaMaxSmall': {
    fs: 6,
    ls: '$ls06',
    lineHeight: '$lh15',
    '&::before': {
      mt: 2,
    },
  },
};
