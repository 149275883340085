import React from 'react';
import loadable from '@loadable/component';
import IHeader from '../../Shared/Models/Headers/Header.interface';
import useQueryHeader from '../../Shared/Models/Headers/useQueryHeader';
import useMedia from '../../Shared/Hooks/useMedia';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';

const MobileHeader = loadable(
  () => import(/* webpackPrefetch: true */ './MobileHeader/MobileHeader')
);
const DesktopHeader = loadable(
  () => import(/* webpackPrefetch: true */ './DesktopHeader/DesktopHeader')
);

type PropType = {
  initData: IHeader;
};

export const HeaderContext = React.createContext({});

function Header({ initData }: PropType) {
  const [headerData] = useQueryHeader(initData);
  const { inEditMode } = useCurrentPage<PageModelBase>();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  return (
    <HeaderContext.Provider value={headerData}>
      {isDesktop || inEditMode ? <DesktopHeader /> : <MobileHeader />}
    </HeaderContext.Provider>
  );
}

const useHeaderData = () => {
  return React.useContext(HeaderContext) as IHeader;
};

export { Header, useHeaderData };
