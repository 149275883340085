export const colors = {
  black: '#181818',
  white: '#ffffff',
  red: '#FF0000',
  grey: '#808080',
  green: '#16BA67',
  blue: '#6F8A95',
  grey100: '#DDDAD8',
  grey200: '#F5F5F5',
  grey300: '#ACA9A8',
  grey400: '#ECECEC',
  grey500: '#7B7978',
  grey550: '#AAAAAA',
  grey600: '#AEAEAE',
  grey700: '#494948',
  grey800: '#717171',
  grey900: '#5C5C5C',
  grey1000: '#484848',
  grey1100: '#2E2E2E',
  grey1200: '#f7f8f9',

  alert200: '#feb2b2',
  alert500: '#9a2626',
  alert600: '#9C2929',

  //Colors with the same purpose need to have the same name on all themes to be overidable.
  //These should be used to support theming and variations, button texts need to be seperated
  //from a specific color so allow themes to controll button, link etc sepretly from the main body
  primary: '$black',
  secondary: '$white',
  accent: '#E7DDD8',
  primary50opacity: '#9d9d9d',
  primary25opacity: '#d1d1d1',
  accent25opacity: '#fdfbf6',

  //Root
  rootBackgroundColor: '#F7F6F5',

  //Backgrounds
  backgroundPrimary: '$secondary',
  backgroundSecondary: '$primary',
  backgroundAccent: '$accent',

  //Text
  textPrimary: '$black',
  textSecondary: '$secondary',

  //Borders
  borderPrimary: '$primary',
  borderBlack: '$black',
  borderSelectedPrimary: '$primary25opacity',
  borderHoverPrimary: '#A3A3A3',

  //Errors
  errorBackground: '$alert200',
  errorText: '$alert600',
  errorTextLighter: '$alert200',
  errorPrimary: '#E30B0B',

  //Success
  successBackground: '$green',
  successText: '$white',

  //Breadcrumbs
  breadcrumbBackgroundPrimary: '$accent',

  //BoxShadow
  shadow015: 'rgba(72, 72, 72, 0.15)',
  shadow005: 'rgba(59, 72, 80, 0.05)',
  shadowGrey: '$grey',

  //Fills
  fillPrimary: '#181818',
  fillSecondary: '$secondary',
  fillError: '$alert500',
  fillWhite: '$white',
  fillAccent: '$accent',

  //Buttons
  buttonPrimary: '$white',
  buttonPrimaryDisabled: '$grey100',
  buttonSecondary: '$black',
  buttonLoadingBackground: '$grey400',
  buttonHoverBackgroundPrimary: '#F1EBE8',
  buttonHoverBackgroundSecondary: '$grey700',
  buttonSmallActiveBackgroundPrimary: '$accent',
  buttonSmallButtonBorder: '$grey700',
  buttonTabButtonActiveBackground: '$backgroundPrimary',
  buttonTabButtonInActive: '$grey100',

  //Toggle
  toggleBackground: '$grey500',
  toggleActiveColor: '$primary',

  //Inputs
  inputGroupPrimaryBackground: '$grey100',

  //List and Listitems
  listItemBackgroundPrimary: '$grey1000',

  //ProductCard
  productCardBorderPrimary: '$grey700',
  productCardTextPrimary: '$textPrimary',

  //QuickSearch
  mobileSearchBackgroundPrimary: '$backgroundPrimary',
  searchInputBorderPrimary: '$grey500',
  searchInputTextPrimary: '$grey700',
  searchInputBlurPrimary: '#grey700',

  //Menu
  menuBackgroundPrimary: '$backgroundPrimary',

  //ValueDropDown
  valueDropdownBackgroundPrimary: '$backgroundPrimary',
  valueDropdownTextPrimary: '$textPrimary',
  valueDropdownBorder: '$grey700',
  valueDropdownContainerBorder: '$grey500',

  //QuantityDropdown
  quantityDropdownBorderPrimary: '$grey700',

  //ProductPage
  productPageBackgroundPrimary: '$grey200',
  productDescriptionTextPrimary: '$textPrimary',

  //CollapsableSection
  collapsableBorderColor: '$grey500',

  //Cart
  cartItemBackgroundPrimary: '$grey1000',
  cartItemProductTextPrimary: '$textPrimary',

  //CartPage
  cartContentTextPrimary: '$grey00',
  cartMainBackgroundPrimary: '$grey200',

  //Ovals
  ovalBackgroundPrimary: '#949494',

  //Pages
  accountInfoGridBoxBackgroundPrimary: '$backgroundAccent',
  accountFormGridBoxBackgroundPrimary: '$grey100',
  accountTextPrimary: '$textPrimary',
  accountLinkButtonBackgroundPrimary: '$grey1000',
  accountCreateAccountButtonBackgroundPrimary: '$grey100',
  accountCreateAccountButtonTextPrimary: '$textPrimary',

  searchPageBorderPrimary: '$grey700',

  //Icon Colors
  iconBlue: '#253B80',
  iconBlueLighter: '#179BD7',
};
