import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

type SearchIconType = IconBaseType & {
  onClick?: () => void;
};

function SearchIcon({
  css,
  color,
  size,
  onClick,
  onBackground,
}: SearchIconType) {
  return (
    <StyledSvg
      size={size}
      color={color}
      css={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      onClick={() => onClick && onClick()}
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11ZM15.5895 16.2856C14.3607 17.3535 12.7558 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.7558 17.3535 14.3607 16.2856 15.5895C16.3095 15.6062 16.3323 15.6251 16.3536 15.6464L20.3536 19.6464C20.5488 19.8417 20.5488 20.1583 20.3536 20.3536C20.1583 20.5488 19.8417 20.5488 19.6464 20.3536L15.6464 16.3536C15.6251 16.3323 15.6062 16.3095 15.5895 16.2856Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {
  fill: '$grey700',
});
const StyledPath = styled(BaseIconPathStyle, {});

export default SearchIcon;
