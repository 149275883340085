import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { timings } from '../../Theme/Settings/animation';
import { styled } from '../../stitches.config';
import {
  animationPathsOut,
  animationPathsIn,
  animationCrossIn,
  animationCrossOut,
} from '../../Theme/Settings/keyframes';

function MenuIcon({ css, color, size, onBackground, isOpen }: IconBaseType) {
  return (
    <StyledSvg
      size={size}
      css={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 11"
    >
      <StyledTopPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        isOpen={isOpen}
        d="M13.5,1.5 C13.7761424,1.5 14,1.72385763 14,2 C14,2.27614237 13.7761424,2.5 13.5,2.5 L2.5,2.5 C2.22385763,2.5 2,2.27614237 2,2 C2,1.72385763 2.22385763,1.5 2.5,1.5 L13.5,1.5 Z"
      />
      <StyledCenterPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        isOpen={isOpen}
        d="M15.5,6.5 C15.7761424,6.5 16,6.72385763 16,7 C16,7.27614237 15.7761424,7.5 15.5,7.5 L0.5,7.5 C0.223857625,7.5 0,7.27614237 0,7 C0,6.72385763 0.223857625,6.5 0.5,6.5 L15.5,6.5 Z"
      />
      <StyledBottomPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        isOpen={isOpen}
        d="M13.5,11.5 C13.7761424,11.5 14,11.7238576 14,12 C14,12.2761424 13.7761424,12.5 13.5,12.5 L2.5,12.5 C2.22385763,12.5 2,12.2761424 2,12 C2,11.7238576 2.22385763,11.5 2.5,11.5 L13.5,11.5 Z"
      />

      <StyledCrossG isOpen={isOpen}>
        <StyledCrossGPath
          d="M15.5,6.5 C15.7761424,6.5 16,6.72385763 16,7 C16,7.27614237 15.7761424,7.5 15.5,7.5 L0.5,7.5 C0.223857625,7.5 0,7.27614237 0,7 C0,6.72385763 0.223857625,6.5 0.5,6.5 L15.5,6.5 Z"
          transform="rotate(-45 6.793 6.5)"
        />
        <StyledCrossGPath
          d="M15.5,6.5 C15.7761424,6.5 16,6.72385763 16,7 C16,7.27614237 15.7761424,7.5 15.5,7.5 L0.5,7.5 C0.223857625,7.5 0,7.27614237 0,7 C0,6.72385763 0.223857625,6.5 0.5,6.5 L15.5,6.5 Z"
          transform="rotate(45 9.207 6.5)"
        />
      </StyledCrossG>
    </StyledSvg>
  );
}

const animationDuration = timings.threeTenths;

const pathsOut = {
  animationName: `${animationPathsOut}`,
  animationDuration,
  animationFillMode: 'forwards',
};

const pathsIn = {
  animationName: `${animationPathsIn}`,
  animationFillMode: 'forwards',
  animationDuration,
};

const StyledSvg = styled(BaseIconStyle, {});

const StyledTopPath = styled(BaseIconPathStyle, {
  transform: 'translate(0 -1)',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
        ...pathsOut,
        animationDelay: timings.oneTenth,
      },
      false: {
        opacity: 0,
        ...pathsIn,
        animationDelay: timings.twoFifths,
      },
    },
  },
});

const StyledCenterPath = styled(BaseIconPathStyle, {
  transform: 'translate(0 -1)',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
        ...pathsOut,
        animationDelay: timings.oneTenth,
      },
      false: {
        opacity: 0,
        ...pathsIn,
        animationDelay: timings.oneHalf,
      },
    },
  },
});

const StyledBottomPath = styled(BaseIconPathStyle, {
  transform: 'translate(0 -1)',
  variants: {
    isOpen: {
      true: {
        opacity: 1,
        ...pathsOut,
      },
      false: {
        opacity: 0,
        ...pathsIn,
        animationDelay: timings.threeFifths,
      },
    },
  },
});

const crossIn = {
  animationName: `${animationCrossIn}`,
  animationDuration,
  animationFillMode: 'forwards',
};

const crossOut = {
  animationName: `${animationCrossOut}`,
  animationDuration,
  animationFillMode: 'forwards',
};

const StyledCrossG = styled('g', {
  transformOrigin: 'center',
  variants: {
    isOpen: {
      true: {
        ...crossIn,
        animationDelay: timings.threeTenths,
        transform: 'scale(0)',
      },
      false: {
        ...crossOut,
        transform: 'scale(1)',
      },
    },
  },
});

const StyledCrossGPath = styled(BaseIconPathStyle, {});

export default MenuIcon;
