import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function TrashClearCartIcon({ css, color, size }: IconBaseType) {
  return (
    <StyledSvg
      size={size}
      color={color}
      css={css}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 14"
    >
      <StyledPath
        d="M2.4,13.7c-0.4,0-0.7-0.1-1-0.4C1.1,13,1,12.7,1,12.3V2H0.6C0.5,2,0.4,2,0.3,1.9C0.2,1.8,0.2,1.7,0.2,1.6
        s0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1h2.9c0-0.2,0.1-0.3,0.2-0.5C3.8,0.6,4,0.5,4.2,0.5h3.7c0.2,0,0.3,0.1,0.5,0.2
        C8.4,0.8,8.5,1,8.5,1.2h2.9c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3s0,0.2-0.1,0.3C11.6,2,11.5,2,11.4,2H11v10.3
        c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4H2.4z M1.8,2v10.3c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1h7.3
        c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.1-0.2,0.1-0.4V2H1.8z M1.8,2v10.3c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.4,0.1H1.8V2z M6,7.3
        L3.7,9.6c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2s0-0.1,0.1-0.2L5.7,7L3.4,4.7c0,0-0.1-0.1-0.1-0.1
        c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1L6,6.7l2.3-2.3c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0.1
        c0.1,0,0.1,0.1,0.1,0.2c0,0.1,0,0.1-0.1,0.2L6.3,7l2.3,2.3c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1-0.1,0.2c0,0.1-0.1,0.1-0.2,0.1
        s-0.1,0-0.2-0.1L6,7.3z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {
  pt: 2,
});

export default TrashClearCartIcon;
