import { styled } from '../../stitches.config';
import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

type PropTypes = IconBaseType & {
  rotateLeft?: boolean;
};

function ArrowIcon({
  css,
  size,
  space,
  color,
  rotateLeft,
  onBackground,
  ...rest
}: PropTypes) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      rotateLeft={rotateLeft}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.14645 0.146447C7.97288 0.320013 7.9536 0.589437 8.08859 0.784306L8.14645 0.853553L14.099 7.5H0.5C0.223858 7.5 0 7.72386 0 8C0 8.27614 0.223858 8.5 0.5 8.5H14.099L8.14645 15.1464C8.05596 15.2369 8 15.3619 8 15.5C8 15.7761 8.22386 16 8.5 16C8.61046 16 8.71255 15.9642 8.79529 15.9035L8.85355 15.8536L16 8L8.85355 0.146447L8.78431 0.0885912C8.58944 -0.0464049 8.32001 -0.0271197 8.14645 0.146447Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {
  variants: {
    rotateLeft: {
      true: {
        transform: 'rotate(180deg)',
      },
    },
  },
});
const StyledPath = styled(BaseIconPathStyle, {});

export default ArrowIcon;
