import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';
import { timings } from '../../Theme/Settings/animation';

type PropTypes = IconBaseType & {
  rotateLeft?: boolean;
  rotateDown?: boolean;
  rotateUp?: boolean;
  transition?: boolean;
};

function ChevronIcon({
  size,
  space,
  color,
  css,
  transition,
  rotateLeft,
  rotateUp,
  rotateDown,
  onBackground,
  ...rest
}: PropTypes) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      transition={transition}
      rotateLeft={rotateLeft}
      rotateUp={rotateUp}
      rotateDown={rotateDown}
      viewBox="0 0 11 20"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        d="M.791 0a.65.65 0 00-.479.195.64.64 0 00-.195.47.64.64 0 00.195.468l8.79 8.789-8.907 8.906a.65.65 0 00-.195.479.65.65 0 00.195.478.654.654 0 00.713.146.639.639 0 00.225-.146l9.394-9.394a.64.64 0 00.196-.47.64.64 0 00-.196-.468L1.27.195A.65.65 0 00.79 0z"
        fillRule="nonzero"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {
  variants: {
    transition: {
      true: {
        transitionDuration: timings.oneFifth,
        transitionProperty: 'all',
      },
    },
    rotateLeft: {
      true: {
        transform: 'rotate(180deg)',
      },
    },
    rotateDown: {
      true: {
        transform: 'rotate(90deg)',
      },
    },
    rotateUp: {
      true: {
        transform: 'rotate(-90deg)',
      },
    },
  },
});
const StyledPath = styled(BaseIconPathStyle, {});

export default ChevronIcon;
