const variations = {
  variants: {
    size: {
      s: {
        fontSize: '14px',
        lineHeight: '24px',
        '@mediaMaxMedium': {
          fontSize: '12px',
          lineHeight: '21px',
        },
      },
      xs: {
        fontSize: '12px',
        lineHeight: '20px',
        '@mediaMaxMedium': {
          fontSize: '10px',
          lineHeight: '16px',
        },
      },
    },
  },
};

export const BaseParagraphStyle = {
  ...variations,
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: '1px',
  '@mediaMaxMedium': {
    fontSize: '14px',
    lineHeight: '23px',
  },
};
