import { OrganizationStructuredData } from '../../../../Molecules/StructuredData/OrganizationStructuredData';
import useMedia from '../../../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../../../Theme/Settings/mediaQueries';
import IFooter from './Footer.interface';
import { FooterDesktop } from './FooterDesktop';
import { FooterMobile } from './FooterMobile';

type PropTypes = {
  initData: IFooter;
};

function Footer({ initData }: PropTypes) {
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  return (
    <>
      {isDesktop ? (
        <FooterDesktop initData={initData} />
      ) : (
        <FooterMobile initData={initData} />
      )}

      <OrganizationStructuredData />
    </>
  );
}

export default Footer;
