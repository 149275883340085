import React, { useReducer } from 'react';
import PageLoader from '../Atoms/Loaders/PageLoader';

type PropType = {
  children: React.ReactNode;
};

const KexLoadingDispatchContext = React.createContext({});

type ActionType = 'add' | 'remove';

const reducer = (state: { count: number }, action: ActionType) => {
  switch (action) {
    case 'add': {
      return { count: state.count + 1 };
    }
    case 'remove': {
      const count = state.count - 1;
      return { count: count < 0 ? 0 : count };
    }
    default: {
      return state;
    }
  }
};

function KexLoadingCircle({ children }: PropType) {
  const [{ count }, dispatch] = useReducer(reducer, { count: 0 });
  const isLoading = count !== 0;

  return (
    <KexLoadingDispatchContext.Provider value={dispatch}>
      <PageLoader loading={isLoading} />
      {children}
    </KexLoadingDispatchContext.Provider>
  );
}

const useKexLoadingCircle: () => React.Dispatch<ActionType> = () => {
  return React.useContext(
    KexLoadingDispatchContext
  ) as React.Dispatch<ActionType>;
};

export { KexLoadingCircle, useKexLoadingCircle };
