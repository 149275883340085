import loadable from '@loadable/component';
import KexPage from './Kex/KexPage';
import { KexRouter } from './Kex/KexRouter/KexRouter';
import { Header } from './Organisms/Header/Header';
import Footer from './Shared/Models/Footers/Footer/Footer';
import { KexLoadingCircle } from './Kex/KexLoadingCircle';
import KexReporterOverlay from './Kex/KexReporterOverlay';
import KexNotificationOverlay from './Molecules/Notifications/BasicNotification';
import ErrorBoundary from './Shared/ErrorBoundary/ErrorBoundary';
import useCurrentPage from '../Features/Shared/Hooks/useCurrentPage';
import AppInitModel from './Shared/Models/AppInitModel.interface';
import PageModelBase from './Shared/Models/PageModelBase.interface';
import { TranslationProvider } from './Shared/Providers/TranslationProvider';
import { AppSettingsProvider } from './Shared/Providers/AppSettingsProvider';
import { UserContextProvider } from './Shared/UserContextProvider/UserContextProvider';
import Breadcrumb from './Molecules/Breadcrumb/Breadcrumb';
import globalStyles from './Styles/global.stitches';
import { ThemeProvider } from './Theme/ThemeProvider';
import { styled } from './stitches.config';
import useMedia from './Shared/Hooks/useMedia';
import { mediaQueryTypes } from './Theme/Settings/mediaQueries';

type PropType = {
  appInitData: AppInitModel;
};

const NotFoundPage = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ '../Features/Pages/NotFoundPage/NotFoundPage'
    )
);

const Content = ({ appInitData }: PropType) => {
  const { pageType, breadcrumb } = useCurrentPage<PageModelBase>();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  return (
    <Container>
      {pageType === 'BlockPreviewPage' ? (
        <KexPage />
      ) : (
        <>
          <Header initData={appInitData.header} />
          {breadcrumb && isDesktop && <Breadcrumb />}
          <KexPage />
          <Footer initData={appInitData.footer} />
          <KexNotificationOverlay />
        </>
      )}
    </Container>
  );
};

const Container = styled('div', {
  backgroundColor: '$rootBackgroundColor',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

function App({ appInitData }: PropType) {
  globalStyles();
  return (
    <AppSettingsProvider data={appInitData.appSettings}>
      <TranslationProvider data={appInitData.appSettings?.translations}>
        <ThemeProvider>
          <UserContextProvider data={appInitData.userState}>
            <KexReporterOverlay />
            <ErrorBoundary FallbackComponent={() => <NotFoundPage />}>
              <KexLoadingCircle>
                <KexRouter
                  appInitData={appInitData}
                  initUrl={appInitData.initUrl}
                >
                  <Content appInitData={appInitData} />
                </KexRouter>
              </KexLoadingCircle>
            </ErrorBoundary>
          </UserContextProvider>
        </ThemeProvider>
      </TranslationProvider>
    </AppSettingsProvider>
  );
}

export default App;
