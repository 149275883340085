export enum GridSize {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Usp = 'usp',
  ContentContainer = 12,
}

export enum GridWidth {
  ContentWidth = 'contentWidth',
  ScreenWidth = 'screenWidth',
  Auto = 'auto',
}
