import { useState, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { IS_PRODUCTION_ENV } from '../Configs/EnvConfig';
import FetchUserState from './FetchUserState';
import UserState from '../Models/UserState.interface';
import useCurrentPage from '../Hooks/useCurrentPage';
import { useAppSettingsData } from '../Providers/AppSettingsProvider';

let hasMounted = false;
let litiumContext = '';
let url = '';

export default function (initialData: UserState): [UserState] {
  const [data, setData] = useState<UserState>(initialData);
  const { languageRoute } = useAppSettingsData();
  const { pageId, channelId } = useCurrentPage();

  litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    channelSystemId: channelId,
  });

  url = `/api/${languageRoute || 'en'}/app/GetUserState`;

  const queryData = useSWR<UserState>(
    url,
    () => FetchUserState(url, litiumContext),
    {
      fallbackData: hasMounted ? undefined : initialData,
      revalidateOnFocus: IS_PRODUCTION_ENV,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (queryData.data) {
        setData(queryData.data);
      }
    }
  }, [queryData.data]);

  return [data];
}

export async function updateUserState() {
  const res = await FetchUserState(url, litiumContext);
  if (res) {
    mutate(url, res, false);
  }
}
