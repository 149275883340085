import { styled } from '../stitches.config';

export const BaseKexLinkStyle = {
  cursor: 'pointer',
  fs: 8,
  fontWeight: '$fw700',
  Letter: 1,
  lineHeight: '$lh175',
  ls: '$ls1',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  variants: {
    color: {
      primary: {
        color: '$textPrimary',
      },
      secondary: {
        color: '$textSecondary',
      },
    },
    noUnderline: {
      true: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    size: {
      s: {
        py: 3.5,
        fs: 5,
        lineHeight: '16px',
        fontWeight: '$fw700',
      },
    },
  },
  defaultVariants: {
    color: 'primary',
  },
};

export const KexLinkBase = styled('a', {
  ...BaseKexLinkStyle,
});
