import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';
import { styled } from '../../stitches.config';

function DoneIcon({ css, size, color, space, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      size={size}
      space={space}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        d="M8 0C8.27614 0 8.5 0.223858 8.5 0.5C8.5 0.776142 8.27614 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 7.72386 15.2239 7.5 15.5 7.5C15.7761 7.5 16 7.72386 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM12.8198 4.17438L12.8891 4.23223C13.0627 4.4058 13.0819 4.67522 12.9469 4.87009L12.8891 4.93934L7.93934 9.88909C7.76577 10.0627 7.49635 10.0819 7.30148 9.94694L7.23223 9.88909L5.11091 7.76777C4.91565 7.5725 4.91565 7.25592 5.11091 7.06066C5.28448 6.88709 5.5539 6.86781 5.74877 7.0028L5.81802 7.06066L7.58508 8.82772L12.182 4.23223C12.3555 4.05867 12.625 4.03938 12.8198 4.17438Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default styled(DoneIcon);
