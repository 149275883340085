import loadable from '@loadable/component';
import LoadingPageType from '../Pages/LoadingPageType/LoadingPageType';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import PageModelBase from '../Shared/Models/PageModelBase.interface';

/* not possible to use full dynamic imports, in server context it wont be able to locate chunks */
const loadablePages = {
  StartPage: loadable(
    () => import(/* webpackPrefetch: true */ '../StartPage/StartPage')
  ),
  StandardPage: loadable(
    () =>
      import(/* webpackPrefetch: true */ '../Pages/StandardPage/StandardPage')
  ),
  ProductPage: loadable(
    () => import(/* webpackPrefetch: true */ '../Pages/ProductPage/ProductPage')
  ),
  BlockPreviewPage: loadable(() => import('../BlockPreview/BlockPreviewPage')),
  CategoryPage: loadable(
    () =>
      import(/* webpackPrefetch: true */ '../Pages/CategoryPage/CategoryPage')
  ),
  CheckoutB2CPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/Checkout/B2C/CheckoutB2CPage'
      )
  ),
  CheckoutPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/Checkout/B2B/CheckoutB2BPage'
      )
  ),
  CartPage: loadable(
    () => import(/* webpackPrefetch: true */ '../Pages/CartPage/CartPage')
  ),
  ActiveOrdersPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/ActiveOrdersPage/ActiveOrdersPage'
      )
  ),
  BatchOrderPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/BatchOrderPage/BatchOrderPage'
      )
  ),
  OrderConfirmationPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/OrderConfirmation/OrderConfirmationPage'
      )
  ),
  OrderDetailsPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/OrderDetailsPage/OrderDetailsPage'
      )
  ),
  OrderHistoryPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/OrderHistoryPage/OrderHistoryPage'
      )
  ),
  CreateNewPasswordPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/CreateNewPasswordPage/CreateNewPasswordPage'
      )
  ),
  ChangePasswordPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/ChangePasswordPage/ChangePasswordPage'
      )
  ),
  SearchPage: loadable(
    () => import(/* webpackPrefetch: true */ '../Pages/SearchPage/SearchPage')
  ),
  RecurringOrdersPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/RecurringOrdersPage/RecurringOrdersPage'
      )
  ),
  RecurringOrderCartPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/RecurringOrderCartPage/RecurringOrderCartPage'
      )
  ),
  LoginPage: loadable(
    () => import(/* webpackPrefetch: true */ '../Pages/LoginPage/LoginPage')
  ),
  ForgotPasswordPage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/ForgotPasswordPage/ForgotPasswordPage'
      )
  ),
  NotFoundPage: loadable(() => import('../Pages/NotFoundPage/NotFoundPage')),
  NewsArchivePage: loadable(
    () =>
      import(
        /* webpackPrefetch: true */ '../Pages/NewsArchivePage/NewsArchivePage'
      )
  ),
  NewsPage: loadable(
    () => import(/* webpackPrefetch: true */ '../Pages/NewsPage/NewsPage')
  ),
  MyPagesPage: loadable(
    () => import(/* webpackPrefetch: true */ '../Pages/MyPagesPage/MyPages')
  ),
  ErrorPage: loadable(() => import('../Pages/ErrorPage/ErrorPage')),
};

const KexPage = () => {
  const { pageType } = useCurrentPage<PageModelBase>();

  const LoadablePage = (loadablePages as any)[pageType];
  return LoadablePage === undefined ? (
    <></>
  ) : (
    <LoadablePage fallback={<LoadingPageType />} />
  );
};

export default KexPage;
