import { styled } from '../../stitches.config';
import { BaseIconStyle, BaseIconPathStyle } from './IconBaseStyle';
import IconBaseType from './IconBaseType';

function DocumentIcon({ css, size, space, color, onBackground }: IconBaseType) {
  return (
    <StyledSvg
      css={css}
      color={color}
      size={size}
      space={space}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        onBackground={onBackground}
        color={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00531768 13.6644L0 13.5V8C0 7.44772 0.447715 7 1 7H4V0.5C4 0.223858 4.22386 0 4.5 0H15.5C15.7761 0 16 0.223858 16 0.5V13C16 14.5977 14.7511 15.9037 13.1763 15.9949L13 16H2.5C1.17452 16 0.0899613 14.9685 0.00531768 13.6644ZM5 13.5C5 14.0632 4.81374 14.583 4.49945 15.001L13 15C14.1046 15 15 14.1046 15 13V1H5V13.5ZM4 8H1L0.999477 13.4677L1.00322 13.5996C1.05416 14.3845 1.70846 15 2.5 15C3.2797 15 3.92045 14.4051 3.99313 13.6445L4 13.5V8ZM13.5 11C13.7761 11 14 11.2239 14 11.5C14 11.7761 13.7761 12 13.5 12H6.5C6.22386 12 6 11.7761 6 11.5C6 11.2239 6.22386 11 6.5 11H13.5ZM13.5 9C13.7761 9 14 9.22386 14 9.5C14 9.77614 13.7761 10 13.5 10H6.5C6.22386 10 6 9.77614 6 9.5C6 9.22386 6.22386 9 6.5 9H13.5ZM8.5 5C8.77614 5 9 5.22386 9 5.5V7.5C9 7.77614 8.77614 8 8.5 8H6.5C6.22386 8 6 7.77614 6 7.5V5.5C6 5.22386 6.22386 5 6.5 5H8.5ZM13.5 7C13.7761 7 14 7.22386 14 7.5C14 7.77614 13.7761 8 13.5 8H10.5C10.2239 8 10 7.77614 10 7.5C10 7.22386 10.2239 7 10.5 7H13.5ZM13.5 5C13.7761 5 14 5.22386 14 5.5C14 5.77614 13.7761 6 13.5 6H10.5C10.2239 6 10 5.77614 10 5.5C10 5.22386 10.2239 5 10.5 5H13.5ZM13.5 3C13.7761 3 14 3.22386 14 3.5C14 3.77614 13.7761 4 13.5 4H6.5C6.22386 4 6 3.77614 6 3.5C6 3.22386 6.22386 3 6.5 3H13.5Z"
      />
    </StyledSvg>
  );
}

const StyledSvg = styled(BaseIconStyle, {});
const StyledPath = styled(BaseIconPathStyle, {});

export default DocumentIcon;
