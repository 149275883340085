import React from 'react';
import { Thing } from 'schema-dts';

interface StructuredDataProps {
  /** A json-ld representation of the Thing you would like to embed.
   * See: @url https://schema.org/Thing#subtypes */
  json: Thing;
}

export const StructuredData: React.FC<StructuredDataProps> = ({ json }) => {
  return (
    <script
      data-testid="structured-data"
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(json),
      }}
    />
  );
};
